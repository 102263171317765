/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/sort-comp */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import SwatchGroup from './SwatchGroup';
import SwatchColorGroup from './SwatchColorGroup';
import SpecSheet from './SpecSheet';
import ImageViewer from './ImageViewer';


import './ProductPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';



Storage.prototype.setObject = (key, object) => window.localStorage.setItem(key, JSON.stringify(object));

Storage.prototype.getObject = (key) => JSON.parse(window.localStorage.getItem(key));

Storage.prototype.pushItem = (key, item) => {
  const currentItems = window.localStorage.getObject(key);

  if (currentItems === null) {
    window.localStorage.setObject(key, [item]);
  } else {
    currentItems.push(item);
    window.localStorage.setObject(key, currentItems);
  }
};

const ImageContainer = styled.div`
  width: 560px;

  @media screen and (max-width: 767px) {
    max-width: 300px;
    width: 100%;
  }

  :hover {
    cursor: zoom-in;
  }
`;

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 1200px) {
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  `;


function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default class ProductPage extends React.Component {
    
  constructor(props) {
    super(props);

    this.state = {
      optionGroups: [],
      id: null,
      name: '',
      lastSku: null,
      imageViewerOpen: false,
      orderNowLink: null,
    };
  }

  fetchData(sku) {
    const baseURL = process.env.REACT_APP_API;
    const fullURL = `${baseURL}/info-full-sku.php?part=${sku}&catalog=${this.props.catalog}`;
    fetch(fullURL)
      .then((res) => res.json())
      .then((res) => this.setState((state) => ({ name: res.length > 0 ? res[0]?.title : state.name })));
  }

  componentDidMount() {
    if (document.referrer && window.parent) {
      setTimeout(() => {
        const message = document.body.scrollHeight;
        window.parent.postMessage(message, `${document.referrer}`);
        console.log(`handler ${message}`);
      }, 1000);
    }
    window.scroll(0, 0);
    if (document.referrer && window.parent) {
      const handler = async () => {
        const message = document.body.scrollHeight;
        window.parent.postMessage(message, `${document.referrer}`);
        console.log(`handler ${message}`);
      };
      window.addEventListener('load', handler, false);
    }
    // Fetch options for sku
    const id = useQuery().get('id');
    this.setState({ id });
    const baseURL = process.env.REACT_APP_API;

    fetch(`${baseURL}/options.php?part=${id}`)
      .then((res) => res.json())
      .then((res) => {
        const optionGroups = [];
        const tiltGroup = [];
        const headRestGroup = [];
        const armGroup = [];
        const controlGroup = [];
        const footringGroup = [];
        const feetGroup = [];
        const upholsteryGroup = [];

        for (const option in res[0]) {
          const value = res[0][option];
          if (value === 1) {
            switch (option.toUpperCase()) {
              case 'T0':
                tiltGroup.push({ option: 'T0', label: 'No Tilt' });
                break;
              case 'T1':
                tiltGroup.push({ option: 'T1', label: 'Seat Tilt' });
                break;
              case 'C1':
                controlGroup.push({ option: 'C1', label: 'Standard Tilt' });
                break;
              case 'C2':
                controlGroup.push({ option: 'C2', label: 'Knee Bend' });
                break;
              case 'A0':
                armGroup.push({ option: 'A0', label: 'None' });
                break;
              case 'A1':
                armGroup.push({ option: 'A1', label: 'Adjustable' });
                break;
              case 'A2':
                armGroup.push({ option: 'A1', label: '2-Way' });
                break;
              case 'A3':
                armGroup.push({ option: 'A2', label: '3D' });
                break;
              case 'NF':
                footringGroup.push({ option: 'NF', label: 'None' });
                break;
              case 'CF20R':
                footringGroup.push({ option: 'CF', label: 'Chrome' });
                break;
              case 'CF20O':
                footringGroup.push({ option: 'CF', label: 'Chrome' });
                break;
              case 'CF18':
                footringGroup.push({ option: 'CF', label: 'Chrome' });
                break;
              case 'BF':
                footringGroup.push({ option: 'BF', label: 'Black' });
                break;
              case 'RC':
                feetGroup.push({ option: 'RC', label: 'Casters' });
                break;
              case 'RG':
                feetGroup.push({ option: 'RG', label: 'Glides' });
                break;
              case 'RG1':
                feetGroup.push({ option: 'RG', label: '1" Glides' });
                break;
              case 'CC':
                feetGroup.push({ option: 'CC', label: 'Chrome Casters' });
                break;
              case 'EC':
                feetGroup.push({ option: 'EC', label: 'ESD Casters' });
                break;
              case 'EG':
                feetGroup.push({ option: 'EG', label: 'ESD Glides' });
                break;
              case 'HC':
                feetGroup.push({ option: 'HC', label: 'Hard Casters' });
                break;
              case 'HCC':
                feetGroup.push({ option: 'HCC', label: 'Hard Chrome' });
                break;
              case 'RBC':
                feetGroup.push({ option: 'RBC', label: 'Casters' });
                break;
              case 'NBC':
                feetGroup.push({ option: 'NBC', label: 'Casters' });
                break;
              case 'H0':
                headRestGroup.push({ option: 'H0', label: 'None' });
                break;
              case 'H1':
                headRestGroup.push({ option: 'H1', label: 'Headrest' });
                break;
              case 'FABRIC':
                upholsteryGroup.push({
                  option: 'F41',
                  label: 'Fabric Burgandy',
                });
                upholsteryGroup.push({ option: 'F42', label: 'Fabric Black' });
                upholsteryGroup.push({ option: 'F43', label: 'Fabric Blue' });
                upholsteryGroup.push({ option: 'F44', label: 'Fabric Gray' });
                upholsteryGroup.push({ option: 'F45', label: 'Fabric Navy' });
                break;
              case 'PATRIOT':
                upholsteryGroup.push({ option: '8540', label: 'Black' });
                upholsteryGroup.push({ option: '8546', label: 'Blue' });
                upholsteryGroup.push({ option: '8569', label: 'Burgundy' });
                upholsteryGroup.push({ option: '8605', label: 'Charcoal' });
                upholsteryGroup.push({ option: '8584', label: 'Taupe' });
                upholsteryGroup.push({ option: '8582', label: 'Imperial' });
                upholsteryGroup.push({ option: '8567', label: 'Dove' });
                upholsteryGroup.push({ option: '8501', label: 'Adobe' });
                upholsteryGroup.push({ option: '8613', label: 'Orange Kist' });
                // upholsteryGroup.push({option: '8528', label: 'Beauty'})
                // upholsteryGroup.push({option: '8546', label: 'Colonial'})
                // upholsteryGroup.push({option: '8529', label: 'Paprika'})
                // upholsteryGroup.push({option: '8579', label: 'Ruby'})
                // upholsteryGroup.push({option: '8532', label: 'Bordeaux'})
                // upholsteryGroup.push({option: '8615', label: 'Berry'})
                // upholsteryGroup.push({option: '8533', label: 'Port'})
                // upholsteryGroup.push({option: '8577', label: 'Purpleg'})
                // upholsteryGroup.push({option: '8614', label: 'Salsa'})
                // upholsteryGroup.push({option: '8504', label: 'Sand'})
                // upholsteryGroup.push({option: '8602', label: 'Oatmeal'})
                // upholsteryGroup.push({option: '8603', label: 'Lark'})
                // upholsteryGroup.push({option: '8506', label: 'Putty'})
                // upholsteryGroup.push({option: '8561', label: 'Mulberry'})
                // upholsteryGroup.push({option: '8610', label: 'Sable'})
                // upholsteryGroup.push({option: '8611', label: 'Terra'})
                // upholsteryGroup.push({option: '8618', label: 'Aubergine '})
                // upholsteryGroup.push({option: '8576', label: 'Pheonician'})
                // upholsteryGroup.push({option: '8542', label: 'Royal'})
                // upholsteryGroup.push({option: '8560', label: 'Aquag'})
                // upholsteryGroup.push({option: '8571', label: 'Forest'})
                // upholsteryGroup.push({option: '8553', label: 'Yew'})
                // upholsteryGroup.push({option: '8581', label: 'Darkaqua'})
                // upholsteryGroup.push({option: '8604', label: 'Concrete'})
                // upholsteryGroup.push({option: '8516', label: 'Chestnut'})
                // upholsteryGroup.push({option: '8517', label: 'Mocha'})
                // upholsteryGroup.push({option: '8539', label: 'Gunmetal'})
                // upholsteryGroup.push({option: '8625', label: 'Cypress'})
                // upholsteryGroup.push({option: '8626', label: 'Eucalyptus'})
                // upholsteryGroup.push({option: '8547', label: 'Midnight'})
                // upholsteryGroup.push({option: '8544', label: 'Sapphire'})
                // upholsteryGroup.push({option: '8572', label: 'Blueridge '})
                // upholsteryGroup.push({option: '8628', label: 'Sea'})
                // upholsteryGroup.push({option: '8564', label: 'Grotto'})
                // upholsteryGroup.push({option: '8541', label: 'Spaceblue '})
                // upholsteryGroup.push({option: '8620', label: 'Peacock'})
                // upholsteryGroup.push({option: '8624', label: 'Granny'})
                // upholsteryGroup.push({option: '8623', label: 'Sage'})
                break;
              case 'SILVERTEX':
                if (this.props.catalog === 'fisher' || this.props.catalog === 'fisheredu') {
                  upholsteryGroup.push({ option: '8822', label: 'Storm' });
                  upholsteryGroup.push({ option: '8809', label: 'Taupe' });
                  upholsteryGroup.push({ option: '8823', label: 'Carbon' });
                  upholsteryGroup.push({ option: '8801', label: 'Marine' });
                  upholsteryGroup.push({ option: '8815', label: 'Borscht' });
                  upholsteryGroup.push({ option: '8840', label: 'Sterling' });
                } else {
                  upholsteryGroup.push({ option: '8822', label: 'Storm' });
                  upholsteryGroup.push({ option: '8809', label: 'Taupe' });
                  upholsteryGroup.push({ option: '8823', label: 'Carbon' });
                  upholsteryGroup.push({ option: '8801', label: 'Marine' });
                  upholsteryGroup.push({ option: '8815', label: 'Borscht' });
                  upholsteryGroup.push({ option: '8840', label: 'Sterling' });
                }
                // upholsteryGroup.push({option: '8822', label: 'Storm'})
                // upholsteryGroup.push({option: '8809', label: 'Taupe'})
                // upholsteryGroup.push({option: '8801', label: 'Marine'})
                // upholsteryGroup.push({option: '8815', label: 'Borscht'})
                // upholsteryGroup.push({option: '8840', label: 'Sterling'})
                // upholsteryGroup.push({option: '8820', label: 'Basil'})
                // upholsteryGroup.push({option: '8811', label: 'Mandarin'})
                // upholsteryGroup.push({option: '8838', label: 'Imperial'})
                // upholsteryGroup.push({option: '8812', label: 'Poppy'})
                // upholsteryGroup.push({option: '8814', label: 'Umber'})
                // upholsteryGroup.push({option: '8816', label: 'Raspberry'})
                // upholsteryGroup.push({option: '8817', label: 'Blackberry'})
                // upholsteryGroup.push({option: '8821', label: 'Bottle'})
                // upholsteryGroup.push({option: '8834', label: 'Acid'})
                // upholsteryGroup.push({option: '8841', label: 'Zest'})
                // upholsteryGroup.push({option: '8819', label: 'Celery'})
                // upholsteryGroup.push({option: '8833', label: 'Creamdementhe'})
                // upholsteryGroup.push({option: '8835', label: 'Peat'})
                // upholsteryGroup.push({option: '8818', label: 'Sage'})
                // upholsteryGroup.push({option: '8831', label: 'Marsh'})
                // upholsteryGroup.push({option: '8813', label: 'Luggage'})
                // upholsteryGroup.push({option: '8837', label: 'Bazaar'})
                // upholsteryGroup.push({option: '8842', label: 'Limoncello'})
                // upholsteryGroup.push({option: '8810', label: 'Chestnut'})
                // upholsteryGroup.push({option: '8806', label: 'Ice'})
                // upholsteryGroup.push({option: '8807', label: 'Cream'})
                // upholsteryGroup.push({option: '8802', label: 'Lagoon'})
                // upholsteryGroup.push({option: '8803', label: 'Turquoise'})
                // upholsteryGroup.push({option: '8804', label: 'Sapphire'})
                // upholsteryGroup.push({option: '8805', label: 'Jet'})
                // upholsteryGroup.push({option: '8839', label: 'Plata'})
                // upholsteryGroup.push({option: '8808', label: 'Champagne'})
                // upholsteryGroup.push({option: '8830', label: 'Neutra'})
                // upholsteryGroup.push({option: '8824', label: 'Meteor'})
                // upholsteryGroup.push({option: '8832', label: 'Bronze'})
                // upholsteryGroup.push({option: '8825', label: 'Mocha'})
                break;
              case 'WHISPER':
                // upholsteryGroup.push({option: '2137', label: 'Navy'})
                // upholsteryGroup.push({option: '2140', label: 'Black'})
                // upholsteryGroup.push({option: '2139', label: 'Charcoal'})
                // upholsteryGroup.push({option: '2138', label: 'Elephant'})
                // upholsteryGroup.push({option: '2121', label: 'Cappuccino'})
                // upholsteryGroup.push({option: '2126', label: 'Bordeaux'})
                // upholsteryGroup.push({option: '2134', label: 'Patina'})
                // upholsteryGroup.push({option: '2129', label: 'Forest'})
                // upholsteryGroup.push({option: '2160', label: 'Sassafras'})
                // upholsteryGroup.push({option: '2162', label: 'Paradise'})
                // upholsteryGroup.push({option: '2164', label: 'Carotene'})
                // upholsteryGroup.push({option: '2165', label: 'Molten'})
                // upholsteryGroup.push({option: '2159', label: 'Zest'})
                // upholsteryGroup.push({option: '2161', label: 'Mallard'})
                // upholsteryGroup.push({option: '2162', label: 'Paradise'})
                // upholsteryGroup.push({option: '2163', label: 'Indigo'})
                // upholsteryGroup.push({option: '2136', label: 'Cerulean'})
                // upholsteryGroup.push({option: '2129', label: 'Forest'})
                // upholsteryGroup.push({option: '2157', label: 'Fog'})
                // upholsteryGroup.push({option: '2154', label: 'Pewter'})
                // upholsteryGroup.push({option: '2134', label: 'Patina'})
                // upholsteryGroup.push({option: '2155', label: 'Storm'})
                // upholsteryGroup.push({option: '2143', label: 'Espresso' })
                // upholsteryGroup.push({option: '2153', label: 'Truffle'})
                // upholsteryGroup.push({option: '2142', label: 'Cognac'})
                // upholsteryGroup.push({option: '2119', label: 'Gravel'})
                // upholsteryGroup.push({option: '2141', label: 'Cashew'})
                // upholsteryGroup.push({option: '2118', label: 'Camel'})
                // upholsteryGroup.push({option: '2120', label: 'Antelope'})
                // upholsteryGroup.push({option: '2150', label: 'Fawn'})
                // upholsteryGroup.push({option: '2117', label: 'Bone'})
                // upholsteryGroup.push({option: '2156', label: 'Putty'})
                // upholsteryGroup.push({option: '2152', label: 'Mahogany'})
                // upholsteryGroup.push({option: '2151', label: 'Ochre'})
                // upholsteryGroup.push({option: '2144', label: 'Sangre'})
                // upholsteryGroup.push({option: '2127', label: 'Merlot'})
                // upholsteryGroup.push({option: '2166', label: 'Brick-Red'})
                // upholsteryGroup.push({option: '2124', label: 'Salsa'})
                // upholsteryGroup.push({option: '2165', label: 'Molten'})
                // upholsteryGroup.push({option: '2164', label: 'Carotene'})
                // upholsteryGroup.push({option: '2123', label: 'Luggage'})
                // upholsteryGroup.push({option: '2125', label: 'Cinnamon'})
                // upholsteryGroup.push({option: '2160', label: 'Sassafras'})
                break;
              case 'FISHER WHISPER':
                if (this.props.catalog === 'fisheredu') {
                  upholsteryGroup.push({ option: '2134', label: 'Patina' });
                  upholsteryGroup.push({ option: '2129', label: 'Forest' });
                  upholsteryGroup.push({ option: '2160', label: 'Sassafras' });
                  upholsteryGroup.push({ option: '2162', label: 'Paradise' });
                  upholsteryGroup.push({ option: '2164', label: 'Carotene' });
                  upholsteryGroup.push({ option: '2165', label: 'Molten' });
                }
                break;
              case 'POLY COLOR':
                upholsteryGroup.push({ option: 'BLU', label: 'Blue' });
                upholsteryGroup.push({ option: 'BLK', label: 'Black' });
                upholsteryGroup.push({ option: 'GRY', label: 'Gray' });
                break;
              case 'ESD VINYL':
                upholsteryGroup.push({ option: 'ESDBLU', label: 'Blue' });
                upholsteryGroup.push({ option: 'ESDBLK', label: 'Black' });
                break;
              case 'ESD FABRIC':
                upholsteryGroup.push({ option: 'ESDBLU', label: 'Blue' });
                upholsteryGroup.push({ option: 'ESDBLK', label: 'Black' });
                break;
              case 'SHELL COLOR':
                upholsteryGroup.push({ option: 'BLK', label: 'Black' });
                upholsteryGroup.push({ option: 'BLU', label: 'Blue' });
                upholsteryGroup.push({ option: 'GRY', label: 'Gray' });
                break;
              default:
                break;
            }
          }
        }

        let { hash } = window.location;
        let options = [];

        if (hash.includes(id) === true) {
          hash = hash.replace(new RegExp(id), '').replace(/#/, '');
          options = hash.split('-').filter((opt) => opt !== '');
        }

        if (tiltGroup.length > 0) {
          const selected = options
            .find((opt) => opt.includes('T'))
            ?.replace(/T/, '');

          optionGroups.push({
            group: tiltGroup,
            selected: selected === undefined ? 0 : parseInt(selected, 10),
            type: 'basic',
            name: 'tilt',
          });
        }

        if (headRestGroup.length > 0) {
          const selected = options
            .find((opt) => opt.includes('H'))
            ?.replace(/H/, '');

          optionGroups.push({
            group: headRestGroup,
            selected: selected === undefined ? 0 : parseInt(selected, 10),
            type: 'basic',
            name: 'headrest',
          });
        }

        if (controlGroup.length > 0) {
          const selected = options
            .find((opt) => opt.includes('C'))
            ?.replace(/C/, '') - 1;

          optionGroups.push({
            group: controlGroup,
            selected: selected === undefined ? 0 : parseInt(selected, 10),
            type: 'basic',
            name: 'control',
          });
        }

        if (armGroup.length > 0) {
          const selected = options
            .find((opt) => opt.includes('A'))
            ?.replace(/A/, '');

          optionGroups.push({
            group: armGroup,
            selected: selected === undefined ? 0 : parseInt(selected, 10),
            type: 'basic',
            name: 'arms',
          });
        }

        if (footringGroup.length > 0) {
          const footringOptions = footringGroup.map((item) => item.option);
          let selectedFootring = null;

          footringOptions.forEach((footring) => {
            if (options.includes(footring) === true) {
              selectedFootring = footring;
            }
          });

          optionGroups.push({
            group: footringGroup,
            selected: selectedFootring === null
              ? 0 : footringOptions.indexOf(selectedFootring),
            type: 'basic',
            name: 'footring',
          });
        }

        if (feetGroup.length > 0) {
          const feetOptions = feetGroup.map((item) => item.option);
          let selectedOption = null;

          feetOptions.forEach((feet) => {
            if (options.includes(feet) === true) {
              selectedOption = feet;
            }
          });

          optionGroups.push({
            group: feetGroup,
            selected: selectedOption === null
              ? 0
              : feetOptions.indexOf(selectedOption),
            type: 'basic',
            name: 'feet',
          });
        }

        if (upholsteryGroup.length > 0) {
          const colors = upholsteryGroup.map((item) => item.option);
          let selectedColor = null;

          colors.forEach((color) => {
            if (options.includes(color) === true) {
              selectedColor = color;
            }
          });

          optionGroups.push({
            group: upholsteryGroup,
            selected: selectedColor === null ? colors[0] : selectedColor,
            type: 'image',
            name: 'upholstery',
          });
        }

        this.setState({ optionGroups });
      });
  }

  onSwatchChange(key, groupName) {
    const { optionGroups } = this.state;
    const optionGroup = optionGroups.find((group) => group.name === groupName);
    optionGroup.selected = key;

    this.setState({
      optionGroups,
    });

    this.updateHash = true;
  }

  onZoomClick(e) {
    this.setState({ imageViewerOpen: true });
  }

  onZoomClose(e) {
    this.setState({ imageViewerOpen: false });
  }

  addTrademark(text) {
    text = text?.replace('Supernova', 'Supernova\u2122');
    text = text?.replace('Trailblazer', 'Trailblazer\u2122');
    text = text?.replace('Voyager', 'Voyager\u2122');
    return text;
  }

  formatSKU(sku) {
    return sku?.replace('NBC', 'RC');
  }

  componentDidUpdate() {
    if (document.referrer && window.parent) {
      const message = document.body.scrollHeight;
      window.parent.postMessage(message, `${document.referrer}`);
      console.log(`handler ${message}`);
    }
  }

  render() {
    let sku = this.state.id;

    this.state.optionGroups.forEach((optionGroup) => {
      if (optionGroup.type === 'basic') {
        sku += `-${optionGroup.group[optionGroup.selected].option}`;
      } else {
        const colorOptions = optionGroup.group.map((group) => group.option);
        sku += `-${colorOptions[colorOptions.indexOf(optionGroup.selected.toString())]}`;
      }
    });

    if (this.state.lastSku !== sku) {
      this.setState({ lastSku: sku });
      this.fetchData(sku);

      const apiURL = process.env.REACT_APP_API;
      const apiV2URL = process.env.REACT_APP_APIV2;
      if (this.props.orderButtonType === 'link') {
        fetch(`${apiURL}/link.php?part=${sku}&partner=${this.props.partner}`)
          .then((res) => res.json())
          .then((res) => this.setState((state) => {
            let link = res?.[0];
            if (link) {
              console.log('has link');
              console.log(link.includes('fishersci'));
              if (link.includes('fishersci')) {
                console.log(link.slice(0, 25));
                let editedLink = link.slice(25);
                // this api costs money to use beyond 100k requests (per hour), so we want to minimize usage, hence restricting when we call the api until the last minute.
                axios.get('https://api.ipregistry.co/?key=t3pp1r30a1o53qtm')
                  .then((response) => {
                    const payload = response.data;
                    console.log(payload.location.country.name);
                    if (payload.location.country.name === 'Canada') {
                      editedLink = `https://www.fishersci.ca${editedLink}`;
                      link = editedLink;
                    }
                  });
              }
            }
            console.log(link);
            return { orderNowLink: link !== undefined ? link : state.orderNowLink };
          }));
      }

      if (
        this.props.partner === 'fisher'
        || this.props.partner === 'fisheredu'
        || this.props.partner === 'thomas'
        || this.props.partner === 'labrepco'
        || this.props.partner === 'bsi'
      ) {
        fetch(`${apiV2URL}/server.php/api/custom-sku?sku=${sku}&partner=${this.props.partner}`)
          .then((res) => res.json())
          .then((res) => this.setState({ customSku: res?.[0] }))
          .catch((error) => console.log(error));
      }

      // eslint-disable-next-line react/no-unused-state
      this.setState({ sku });

      if (this.updateHash === true) {
        window.history.replaceState({}, '', `#${sku}`);
        this.updateHash = false;
      }
    }
    

    const baseURL = (this.props.catalog === 'fisher'
      || this.props.catalog === 'fisheredu')
      ? `${process.env.REACT_APP_S3}/images/700px/fisher/`
      : `${process.env.REACT_APP_S3}/images/700px/standard/`;

    const fullSizeImageBase = (this.props.catalog === 'fisher'
      || this.props.catalog === 'fisheredu')
      ? `${process.env.REACT_APP_S3}/images/full/fisher/`
      : `${process.env.REACT_APP_S3}/images/full/standard/`;

    return (
      <Container>
        {
          this.state.imageViewerOpen === true
            ? (
              <ImageViewer
                src={`${fullSizeImageBase + sku}.png`}
                onClose={this.onZoomClose.bind(this)}
              />
            )
            : ''
        }
        <div className="product-root">
        
       
          <ImageContainer
            onClick={this.onZoomClick.bind(this)}
          >
            <img
              className="main-image"
              src={`${baseURL + sku}.png`}
              alt="Product"
            />
          </ImageContainer>
          <div style={{ padding: '10px' }}>
            <h3>{this.addTrademark(this.state.name)}</h3>
            <h6 className="sku">
              SKU:
              {' '}
              {this.formatSKU(this.state.customSku == undefined ? sku : this.state.customSku)}
            </h6>
            <hr style={{ borderTop: '2px solid rgba(0,0,0,.1)' }} />

            {this.state.optionGroups.map((optionGroup, key) => (optionGroup.type === 'basic'
              ? (
                <SwatchGroup
                  name={optionGroup.name}
                  swatches={optionGroup}
                  selected={optionGroup.selected}
                  key={key}
                  onSwatchChange={this.onSwatchChange.bind(this)}
                />
              )
              : (
                <SwatchColorGroup
                  name={optionGroup.name}
                  swatches={optionGroup}
                  selected={optionGroup.selected}
                  key={key}
                  onSwatchChange={this.onSwatchChange.bind(this)}
                  catalog={this.props.catalog}
                />
              )))}
            {
              this.props.orderButtonType === 'rfq'
                ? (
                  <Button
                    className="request-btn"
                    onClick={() => {
                      if (localStorage.getObject('skus')?.find((obj) => obj.sku === sku) === undefined) {
                        localStorage.pushItem('skus', { sku, quantity: 1 });
                      }

                      window.location.href = '/quote';
                    }}
                    variant="primary"
                  >
                    Request Quote
                  </Button>
                )
                : (this.props.orderButtonType === 'none')
                  ? ''
                  : (
                    <Button
                      ref="requestBtn"
                      className="request-btn"
                      href={this.state.orderNowLink}
                      variant="primary"
                    >
                      Order Now
                    </Button>
                  )
            }
          </div>
        </div>
        <SpecSheet
          part={sku}
          logoURL={this.props.logoURL}
          title={this.state.name}
          partner={document.partner}
          catalog={this.props.catalog}
        />
      </Container>
    );
  }
}
