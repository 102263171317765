import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g data-name="Group 441" transform="translate(-937 -107)">
        <circle
          cx="18"
          cy="18"
          r="18"
          data-name="Ellipse 7"
          transform="translate(937 107)"
        />
        <path
          fill="#fff"
          d="M15.253 5.695c.011.151.011.3.011.453a9.845 9.845 0 01-9.914 9.913A9.846 9.846 0 010 14.5a7.208 7.208 0 00.841.043 6.978 6.978 0 004.326-1.489 3.49 3.49 0 01-3.258-2.416 4.394 4.394 0 00.658.054 3.685 3.685 0 00.917-.119A3.485 3.485 0 01.69 7.151v-.043a3.509 3.509 0 001.575.442 3.489 3.489 0 01-1.078-4.66 9.9 9.9 0 007.184 3.646 3.933 3.933 0 01-.086-.8 3.487 3.487 0 016.03-2.384 6.86 6.86 0 002.211-.841 3.475 3.475 0 01-1.532 1.92A6.985 6.985 0 0017 3.893a7.49 7.49 0 01-1.747 1.8z"
          data-name="Icon awesome-twitter"
          transform="translate(947 115.746)"
        />
      </g>
    </svg>
  );
}

export default Icon;
