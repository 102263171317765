import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M0 0H16V16H0z"
            data-name="Rectangle 52"
            transform="translate(1084 3350)"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-path)"
        data-name="Mask Group 17"
        transform="translate(-1084 -3350)"
      >
        <g transform="translate(1084 3350)">
          <path fill="none" d="M0 0h16v16H0z" data-name="Path 33" />
          <path
            d="M4.413 7.193a10.1 10.1 0 004.393 4.393l1.467-1.467a.663.663 0 01.68-.16 7.6 7.6 0 002.38.38.669.669 0 01.667.667v2.327a.669.669 0 01-.667.667A11.332 11.332 0 012 2.667.669.669 0 012.667 2H5a.669.669 0 01.667.667 7.574 7.574 0 00.38 2.38.669.669 0 01-.167.68z"
            data-name="Path 34"
          />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
