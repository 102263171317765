import React from 'react'
import { SwatchImage } from './Swatch'

import './SwatchGroup.css'

export default class SwatchColorGroup extends React.Component {
  onSwatchClick(key) {
    this.props.onSwatchChange(key, this.props.name)
  }

  clusterMaterial(swatches) {
    let group85 = {
      label: this.props.catalog === 'partner' ? 'Trailblazer\u2122 (Mid-grade vinyl)' : 'Grade B (Mid-grade vinyl)',
      options: []
    }
    let group88 = {
      label: this.props.catalog === 'partner' ? 'Supernova\u2122 (Antibacterial high-end textured vinyl)' : 'Grade A (Antibacterial high-end textured vinyl)',
      options: []
    }
    let group21 = {
      label: this.props.catalog === 'partner' ? 'Voyager\u2122 Vinyl' : 'Grade C',
      options: []
    }
    let groupOther = {label: 'Color', options: []}

    swatches.forEach(swatch => {
      if (swatch.option.includes('88')) {
        group88.options.push(swatch)
      } else if (swatch.option.includes('21')) {
        group21.options.push(swatch)
      } else if (swatch.option.includes('85') || swatch.option.includes('86')){
        group85.options.push(swatch)
      } else {
        groupOther.options.push(swatch)
      }
    })

    let groups = []

    if (group85.options.length > 0) {
      groups.push(group85)
    }

    if (group88.options.length > 0) {
      groups.push(group88)
    }

    if (group21.options.length > 0) {
      groups.push(group21)
    }

    if (groupOther.options.length > 0) {
      groups.push(groupOther)
    }

    return groups
  }

  render() {
    return (
      <div>
        {this.clusterMaterial(this.props.swatches.group).map((swatchGroup, key) => {
          console.log(swatchGroup)
          return <SwatchMaterialGroup
            title={swatchGroup.label}
            swatches={swatchGroup.options}
            selected={this.props.swatches.selected}
            onSwatchChange={this.onSwatchClick.bind(this)}
          />})
          }
      </div>
    )
  }
}

class SwatchMaterialGroup extends React.Component {
  onSwatchClick(key) {
    this.props.onSwatchChange(key, this.props.name)
  }

  render() {
    return (
      <div>
        <h6 className="swatch-group-label">{this.props.title}</h6>
        <div className="swatch-group">
          {this.props.swatches.map((swatch, key) => {
            return <SwatchImage
              key={key}
              option={swatch.option}
              selectedKey={swatch.option}
              selected={swatch.option === this.props.selected}
              label={swatch.label}
              onSwatchClick={this.onSwatchClick.bind(this)}
            />
          })}
        </div>
      </div>
    )
  }
}
