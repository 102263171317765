/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  Nav, Navbar, NavDropdown, Form,
} from 'react-bootstrap';

function SearchBar() {
  const [value, setValue] = React.useState('');
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        const query = value;
        window.location.assign(`/search?query=${query}`);
      }}
    >
      <Form.Group controlId="queryFieldMobile">
        <Form.Control
          style={{ float: 'right', width: '100%', marginTop: '1px' }}
          type="search"
          placeholder="Search seating..."
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      </Form.Group>
    </Form>
  );
}

export function Navigation(props) {
  const instructionURL = `//api.ecomseating.com/create-instructions.php?logoURL=${props.url}&partner=${props.partner}`;
  const cleaningURL = '//api.ecomseating.com/images/pdfs/SEFA-cleaning.pdf';
  const warrantyURL = `//apiv2.ecomseating.com/server.php/api/warranty?partner=${
    props.partner}&logoURL=${props.url}`;

  if (props.extraAttributes && props.extraAttributes.standard) {
    return (
      <Navbar className="home-navigation" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link href={props.extraAttributes.standard}>Home</Nav.Link>
            <NavDropdown
              title="Products"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                href="/laboratory-seating"
              >
                Laboratory Seating
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/office-seating"
              >
                Office Seating
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/industrial-seating"
              >
                Industrial Seating
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/medical-seating"
              >
                Medical Seating
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/controlled-environment-seating"
              >
                ESD & Clean Room Seating
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/chair-guide">Build a Chair</Nav.Link>
            <NavDropdown
              title="Resources"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                href={instructionURL}
              >
                Instruction Sheet
              </NavDropdown.Item>
              <NavDropdown.Item
                href={warrantyURL}
              >
                Warranty
              </NavDropdown.Item>
              <NavDropdown.Item
                href={cleaningURL}
              >
                SEFA Cleaning Protocol
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <SearchBar />
            <Nav.Link href="/quote">Quote</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
  return (
    <Navbar className="home-navigation" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link href="/">Home</Nav.Link>
          <NavDropdown
            title="Products"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item
              href="/laboratory-seating"
            >
              Laboratory Seating
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/office-seating"
            >
              Office Seating
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/industrial-seating"
            >
              Industrial Seating
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/medical-seating"
            >
              Medical Seating
            </NavDropdown.Item>
            <NavDropdown.Item
              href="/controlled-environment-seating"
            >
              ESD & Clean Room Seating
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/chair-guide">Build a Chair</Nav.Link>
          <NavDropdown
            title="Resources"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item
              href={instructionURL}
            >
              Instruction Sheet
            </NavDropdown.Item>
            <NavDropdown.Item
              href={warrantyURL}
            >
              Warranty
            </NavDropdown.Item>
            <NavDropdown.Item
              href={cleaningURL}
            >
              SEFA Cleaning Protocol
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav>
          <SearchBar />
          <Nav.Link href="/quote">Quote</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
