import React from 'react'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

export class Swatch extends React.Component {
  onClick(e) {
    this.props.onSwatchClick(e.target.id)
  }

  render() {
    return (
      <div className="swatch">
        <Button
          id={this.props.selectedkey}
          onClick={this.onClick.bind(this)}
          active={this.props.selected}
          variant="outline-info"
        >
          {this.props.label}
        </Button>
      </div>
    )
  }
}

export class SwatchImage extends React.Component {
  onClick(e) {
    this.props.onSwatchClick(e.target.id)
  }

  renderTooltip(props) {
    const baseURL =  'https://ecomseating.s3.amazonaws.com/images/swatches/'
    return <Tooltip
        onClick={(e) => e.stopPropagation()}
        {...props}
       >
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <img
            src={baseURL + this.props.option + '.jpg'}
            width="180px"
            height="180px"
            alt="Color swatch"
          />
         {this.props.label}
        </div>
       </Tooltip>;
  }

  render() {
    const baseURL =  'https://ecomseating.s3.amazonaws.com/images/swatches/'
    return (
      <div
        className="swatch-img"
        id={this.props.selectedKey}
        onClick={this.onClick.bind(this)}
        style={this.props.selected ? { border: 'solid 4px #17a2b8', margin: '4px' } : {margin: '4px'}}
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={this.renderTooltip.bind(this)}
          label={this.props.label}
        >
          <img
            width={this.props.selected ? "26px" : "34px"}
            height={this.props.selected ? "26px" : "34px"}
            id={this.props.selectedKey}
            src={baseURL + this.props.option + '.jpg'}
            alt="Color swatch"
          />
        </OverlayTrigger>
      </div>
    )
  }
}
