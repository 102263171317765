import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="32"
      viewBox="0 0 49 32"
    >
      <path d="M14.059 10.283h4.24l-6.302 15.472-4.236.003-3.259-12.329c2.318.952 4.379 3.022 5.219 5.275l.42 2.148zm3.357 15.488l2.503-15.501h4.001l-2.503 15.501h-4.002zm14.576-9.277c2.31 1.106 3.375 2.444 3.362 4.211-.032 3.217-2.765 5.295-6.97 5.295-1.796-.02-3.526-.394-4.459-.826l.56-3.469.515.246c1.316.579 2.167.814 3.769.814 1.151 0 2.385-.476 2.396-1.514.007-.679-.517-1.165-2.077-1.924-1.518-.74-3.53-1.983-3.505-4.211.024-3.012 2.809-5.116 6.765-5.116 1.55 0 2.795.339 3.586.651l-.542 3.36-.359-.178a7.042 7.042 0 00-2.995-.595c-1.568 0-2.293.689-2.293 1.333-.01.728.848 1.204 2.246 1.923zm14.207-6.209l3.239 15.49h-3.714s-.368-1.782-.488-2.322l-5.125-.008c-.156.42-.841 2.331-.841 2.331h-4.205l5.944-14.205c.419-1.011 1.138-1.285 2.097-1.285h3.093zm-4.936 9.989h3.322c-.159-.775-.927-4.474-.927-4.474l-.27-1.337c-.202.581-.554 1.52-.531 1.479 0 0-1.262 3.441-1.594 4.332zm-31.54-1.572C8.075 14.129 4.439 11.711 0 10.593l.053-.322h6.453c.87.034 1.573.326 1.815 1.308z" />
    </svg>
  );
}

export default Icon;
