/* eslint-disable max-classes-per-file */
import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import './SpecSheet.css';

export default class SpecSheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      res: [],
      sku: '',
      description: '',
      showSpecsSpinner: false,
      showInstructionsSpinner: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    this.fetchData();
  }

  fetchData() {
    if (this.props.part !== this.state.sku) {
      const baseURL = process.env.REACT_APP_API;
      fetch(`${baseURL}/specs.php?part=${this.props.part}`)
        .then((res) => res.json())
        .then((res) => this.setState((state) => ({
          res: res.length > 0 ? res : state.res,
          sku: this.props.part,
        })))
        .catch(() => {
          this.setState({
            sku: this.props.part,
          });
        });

      fetch(`${baseURL}/description.php?part=${
        this.props.part
      }&catalog=${this.props.catalog}`)
        .then((res) => res.json())
        .then((res) => this.setState((state) => ({
          description: res !== null ? res : state.description,
          sku: this.props.part,
        })))
        .catch(() => {
          this.setState({
            sku: this.props.part,
          });
        });
    }
  }

  onSpecsPDFClick(e) {
    const baseURL = process.env.REACT_APP_PDFGEN;

    this.setState({ showSpecsSpinner: true });
    const url = `${baseURL}?sku=${this.state.sku
    }&catalog=${this.props.catalog
    }&partner=${this.props.partner.toLowerCase()
    }&file=${this.state.sku}.pdf`;
    window.open(url);
    this.setState({ showSpecsSpinner: false });
  }

  onInstructionsPDFClick(e) {
    this.setState({ showInstructionsSpinner: true });
    const baseURL = process.env.REACT_APP_API;
    window.open(`${baseURL
    }/create-instructions.php?logoURL=${
      this.props.logoURL}&sku=${this.state.sku
    }&partner=${this.props.partner.toLowerCase()}`);
    this.setState({ showInstructionsSpinner: false });
  }

  addTrademark(text) {
    console.log({ text });
    text = text?.replace('Supernova', 'Supernova\u2122');
    text = text?.replace('Trailblazer', 'Trailblazer\u2122');
    text = text?.replace('Voyager', 'Voyager\u2122');
    return text;
  }

  replaceFisher(text) {
    if (this.props.catalog === 'fisher' || this.props.catalog === 'fisheredu') {
      text = text?.replace('Supernova\u2122', 'Grade A');
      text = text?.replace('Trailblazer\u2122', 'Grade B');
      text = text?.replace('Voyager\u2122', 'Grade C');
      text = text?.replace('round', 'oval');
    }
    return text;
  }

  addWarrantyLink(text) {
    const baseURL = process.env.REACT_APP_APIV2;
    if (text.includes('Warranty') === true) {
      return (
        <a
          target="_blank"
          href={`${baseURL}/server.php/api/warranty?partner=${
            this.props.partner.toLowerCase()
          }&logoURL=${this.props.logoURL}`}
          style={{ color: '#007bff' }}
          rel="noreferrer"
        >
          {' '}
          Warranty
        </a>
      );
    }

    return '';
  }

  render() {
    this.fetchData();
    return (
      <div className="spec-sheet" style={{ padding: '10px' }}>
        <hr style={{ borderTop: '2px solid rgba(0,0,0,.1)' }} />
        <h4>Details</h4>
        <p>{this.addTrademark(this.state.description)}</p>
        <hr style={{ borderTop: '2px solid rgba(0,0,0,.1)' }} />
        <h4>Specifications</h4>
        <table>
          <tbody>
            {this.state.res.map((res) => (
              <tr>
                <td>{res[0]}</td>
                <td>
                  {
                    this.replaceFisher(
                      this.addTrademark(res[1].toString()),
                    )
                  }
                  { this.addWarrantyLink(res[1].toString()) }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={
          {
            marginTop: '40px',
            marginBottom: '40px',
            width: '100%',
            display: 'flex',
          }
}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
            <Button
              onClick={this.onSpecsPDFClick.bind(this)}
              variant="dark"
              type="submit"
            >
              { this.state.showSpecsSpinner === true
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
                : 'Specs PDF'}
            </Button>
            <Button
              onClick={this.onInstructionsPDFClick.bind(this)}
              variant="dark"
              type="submit"
            >
              { this.state.showInstructionsSpinner === true
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )
                : 'Instructions PDF'}
            </Button>
          </div>
        </div>
        <EmailForm
          sku={this.state.sku}
          logoURL={this.props.logoURL}
          partner={this.props.partner}
          catalog={this.props.catalog}
          title={document.title}
        />
      </div>
    );
  }
}

class EmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
    };
  }

  onSubmit(e) {
    this.setState({ showSpinner: true });
    e.preventDefault();
    console.log(window.location.href);
    const email = document.getElementById('emailField').value;

    const pdfURL = process.env.REACT_APP_PDFGEN;

    const url = `${pdfURL}?sku=${this.props.sku
    }&email=${email
    }&partner=${this.props.partner.toLowerCase()
    }&catalog=${this.props.catalog
    }&link=${encodeURIComponent(window.location.href)
    }&title=${this.props.title}`;
    fetch(url, {
      method: 'POST',
    })
      .then(() => this.setState({ showSpinner: false }))
      .catch(() => this.setState({ showSpinner: false }));
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit.bind(this)}>
        <Form.Group controlId="emailField">
          <Form.Label>Email Specification Sheet</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Enter email"
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Button variant="dark" type="submit">
          { this.state.showSpinner === true
            ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )
            : 'Email Specs'}
        </Button>
      </Form>
    );
  }
}
