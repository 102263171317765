import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g data-name="Group 443" transform="translate(-848 -107)">
        <circle
          cx="18"
          cy="18"
          r="18"
          data-name="Ellipse 5"
          transform="translate(848 107)"
        />
        <path
          fill="#fff"
          d="M3.805 17H.281V5.65h3.524zM2.041 4.1a2.051 2.051 0 112.041-2.06A2.058 2.058 0 012.041 4.1zM17 17h-3.521v-5.525c0-1.317-.027-3.005-1.832-3.005-1.832 0-2.113 1.431-2.113 2.91V17H6.013V5.65h3.38V7.2h.049a3.7 3.7 0 013.335-1.833c3.567 0 4.223 2.349 4.223 5.4V17z"
          data-name="Icon awesome-linkedin-in"
          transform="translate(858 117)"
        />
      </g>
    </svg>
  );
}

export default Icon;
