/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// import Head from 'next/head'
// import Header from '../components/header'
// import Footer from '../components/footer'
// import Navigation from '../components/navigation'
import './BuildAChair2.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { devEnv, prodEnv, REACT_NATIVE_APIV2 } from '../constants';

function AppV2(props) {
  const iframeRef = React.useRef(null);
  const [small, setSmall] = React.useState(false);
  const cookies = new Cookies();

  React.useEffect(() => {
    const height = window.innerHeight;
    console.log({ height });
    if (height < 750) {
      setSmall(true);
    } else {
      setSmall(false);
    }
  });
  React.useEffect(() => {
    const handler = async (event) => {
      const { data } = event;
      if (event.origin == devEnv || event.origin == prodEnv) {
        if (data.method === 'Fisher') {
          // use axios to search the catalogAPIv2
          axios.get(`${REACT_NATIVE_APIV2}/server.php/api/fisher`)
            .then((response) => {
              // store the result in a treeData variable and send it back
              const resData = JSON.parse(response.data);
              const treeData = { method: 'treeData', payload: { treeData: resData } };
              event.source.postMessage(treeData, event.origin);
            })
            .catch((e) => console.log(e));
        } else if (data.method === 'Partner') {
          axios.get(`${REACT_NATIVE_APIV2}/server.php/api/partner`)
            .then((response) => {
              const resData = JSON.parse(response.data);
              const treeData = { method: 'treeData', payload: { treeData: resData } };
              event.source.postMessage(treeData, event.origin);
            })
            .catch((e) => console.log(e));
        } else if (data.method === 'Open Cart') {
          // if we have any items in cart
          let cart = cookies.get('@rfqCart');
          if (cart) {
            // push new item onto cart using payload as long as it does not already exist
            if (cart.find((item) => item.sku == data.payload.sku) == undefined) {
              cart.push(data.payload);
              // set the cookie again
              cookies.set('@rfqCart', cart);
            }
          } else {
            cart = [data.payload];
            // create a new @rfqCart cookie and set it to a stringified payload
            cookies.set('@rfqCart', cart);
          }
          // post cart back to event origin
          event.source.postMessage({ method: 'Open Cart', payload: cart }, event.origin);
        } else if (data.method === 'Delete Item') {
          const cart = cookies.get('@rfqCart');
          const newCart = cart.filter((item) => item.sku !== data.payload.sku);
          if (newCart.length > 0) {
            cookies.set('@rfqCart', newCart);
          } else {
            cookies.remove('@rfqCart');
          }
        } else if (data.method === 'Change Quantity') {
          const dataItem = data.payload;
          const cart = cookies.get('@rfqCart');

          if (cart) {
            if (cart.find((item) => item.sku == dataItem.sku) != undefined) {
              cart.find((item) => item.sku == dataItem.sku).quantity = dataItem.value;
              cookies.set('@rfqCart', cart);
            }
          }
        } else if (data.method === 'Retrieve Cart') {
          const cart = cookies.get('@rfqCart');
          const message = { method: 'Retrieve Cart', payload: cart };
          event.source.postMessage(message, event.origin);
        } else if (data.method === 'Remove Cart') {
          cookies.remove('@rfqCart');
        }
      }
      // event.source.postMessage("Hello back to you!", event.origin)
    };

    window.addEventListener('message', handler);
    if (document.referrer && window.parent) {
      setTimeout(() => {
        const message = document.body.scrollHeight;
        window.parent.postMessage(message, `${document.referrer}`);
        console.log(`handler ${message}`);
      }, 1000);
    }
    if (document.referrer && window.parent) {
      const handler = async () => {
        const message = document.body.scrollHeight;
        window.parent.postMessage(message, `${document.referrer}`);
        console.log(`handler ${message}`);
      };
      window.addEventListener('load', handler, false);
    }
    // // clean up
    // return () => window.removeEventListener("message", handler)
  }, []); // empty array => run only once

  if (!props.folder) {
    return (
      <main>
        <div style={{
          width: '100%', display: 'flex', flexDirection: 'column', alignItem: 'center', justifyContent: 'center', textAlign: 'left',
        }}
        >
          <iframe ref={iframeRef} id="build-a-chair" className="iFrame" src={`${prodEnv}?p=${props.partner}`} height={small ? 550 : 750} width={1200} />
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            Our seating is designed using ergonomic principles and research data to offer a full range of options to be applied to the various tasks and environments we work within. The key to optimal work posture is for your chair and work surface to be in proper height relationship. This guide will assist you in choosing the right chair product to match your work situation.
          </h4>
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            <strong>1.</strong>
            {' '}
            Your work surface height level (desk, bench, hood, etc.) determines the seat height range you choose for your chair. This table gives you the proper seat height range in relation to your work surface:
          </h4>
          <table style={{ margin: 'auto', padding: '20px' }} border="1" cellPadding="1" cellSpacing="1">
            <tbody>
              <tr>
                <td><strong>Work Surface Height Range</strong></td>
                <td><strong>Chair Seat Height Range</strong></td>
              </tr>
              <tr>
                <td>29" - 31"</td>
                <td>16" - 21"</td>
              </tr>
              <tr>
                <td>32" - 36"</td>
                <td>22" - 28"</td>
              </tr>
              <tr>
                <td>37" - 42"</td>
                <td>26" - 35"</td>
              </tr>
            </tbody>
          </table>
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            <strong>2.</strong>
            {' '}
            The environment where you work will help determine what material to choose on the seat and back of your chair. If it is likely that there will be chemical spills or residue, the optional self-skinning polyurethane material should be considered because it is easy to clean – simply wipe down with a moist Cloth for effortless cleanup. For longer periods of sitting, a flexible molded polyurethane seat is a good choice because of its durability compared to regular cut polyurethane foam material. The molded polyurethane foam can be upholstered with either a durable vinyl material or, in environments where it is suitable, a quality Cloth material.
          </h4>
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            <strong>3.</strong>
            {' '}
            Depending on the floor surface and task to be performed while seated, your chair needs to have the proper safety and mobility options available. If you need mobility on a hard surface floor, such as concrete, tile or vinyl, a soft rubber caster wheel is the best choice. For carpeted floors, a hard nylon caster is preferred. If safety is the primary issue, nylon glides are normally the best option.
          </h4>
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            <strong>4.</strong>
            {' '}
            If your work surface is above 32”, a foot ring (which fits on all chairs or stools starting at seat heights of 18 inches) should be used. The chair’s foot rings are adjustable and very stable and allow you to keep the right relationship between your thighs and feet to maintain circulation in the lower extremities.
          </h4>
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            <strong>5.</strong>
            {' '}
            To assure that proper posture is maintained, the backrest of your chair or stool must be adjustable both horizontally and vertically to properly support the lumbar area of your back. This is particularly important for long-term sitting to prevent “slouching,” which often leads to back spasms and undue pressure on the front of the intervertebral discs in the spinal column.
          </h4>
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            <strong>6.</strong>
            {' '}
            If your work involves having to lean forward (for example, to look into a microscope), a forward seat tilt option should be considered. This will allow you to maintain an open angle in your hip and enable you to relieve the added pressure normally exerted on the lumbar area of your back when leaning forward.
          </h4>
          <h4 style={{
            padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
          }}
          >
            <strong>7.</strong>
            {' '}
            Lastly, to create a pleasant, attractive work environment, color is important. Our chairs are available with a wide range of color options to fit almost any color plan.
          </h4>
        </div>
      </main>
    );
  }

  return (
    <main>
      <div style={{
        width: '100%', display: 'flex', flexDirection: 'column', alignItem: 'center', justifyContent: 'center', textAlign: 'left',
      }}
      >
        <iframe ref={iframeRef} id="build-a-chair" className="iFrame" src={`${prodEnv}?p=${props.folder}`} height={small ? 550 : 750} width={1200} />
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          Our seating is designed using ergonomic principles and research data to offer a full range of options to be applied to the various tasks and environments we work within. The key to optimal work posture is for your chair and work surface to be in proper height relationship. This guide will assist you in choosing the right chair product to match your work situation.
        </h4>
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          <strong>1.</strong>
          {' '}
          Your work surface height level (desk, bench, hood, etc.) determines the seat height range you choose for your chair. This table gives you the proper seat height range in relation to your work surface:
        </h4>
        <table style={{ margin: 'auto', padding: '20px' }} border="1" cellPadding="1" cellSpacing="1">
          <tbody>
            <tr>
              <td><strong>Work Surface Height Range</strong></td>
              <td><strong>Chair Seat Height Range</strong></td>
            </tr>
            <tr>
              <td>29" - 31"</td>
              <td>16" - 21"</td>
            </tr>
            <tr>
              <td>32" - 36"</td>
              <td>22" - 28"</td>
            </tr>
            <tr>
              <td>37" - 42"</td>
              <td>26" - 35"</td>
            </tr>
          </tbody>
        </table>
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          <strong>2.</strong>
          {' '}
          The environment where you work will help determine what material to choose on the seat and back of your chair. If it is likely that there will be chemical spills or residue, the optional self-skinning polyurethane material should be considered because it is easy to clean – simply wipe down with a moist Cloth for effortless cleanup. For longer periods of sitting, a flexible molded polyurethane seat is a good choice because of its durability compared to regular cut polyurethane foam material. The molded polyurethane foam can be upholstered with either a durable vinyl material or, in environments where it is suitable, a quality Cloth material.
        </h4>
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          <strong>3.</strong>
          {' '}
          Depending on the floor surface and task to be performed while seated, your chair needs to have the proper safety and mobility options available. If you need mobility on a hard surface floor, such as concrete, tile or vinyl, a soft rubber caster wheel is the best choice. For carpeted floors, a hard nylon caster is preferred. If safety is the primary issue, nylon glides are normally the best option.
        </h4>
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          <strong>4.</strong>
          {' '}
          If your work surface is above 32”, a foot ring (which fits on all chairs or stools starting at seat heights of 18 inches) should be used. The chair’s foot rings are adjustable and very stable and allow you to keep the right relationship between your thighs and feet to maintain circulation in the lower extremities.
        </h4>
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          <strong>5.</strong>
          {' '}
          To assure that proper posture is maintained, the backrest of your chair or stool must be adjustable both horizontally and vertically to properly support the lumbar area of your back. This is particularly important for long-term sitting to prevent “slouching,” which often leads to back spasms and undue pressure on the front of the intervertebral discs in the spinal column.
        </h4>
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          <strong>6.</strong>
          {' '}
          If your work involves having to lean forward (for example, to look into a microscope), a forward seat tilt option should be considered. This will allow you to maintain an open angle in your hip and enable you to relieve the added pressure normally exerted on the lumbar area of your back when leaning forward.
        </h4>
        <h4 style={{
          padding: '20px', lineHeight: 2, maxWidth: 1000, fontSize: 14, margin: 'auto',
        }}
        >
          <strong>7.</strong>
          {' '}
          Lastly, to create a pleasant, attractive work environment, color is important. Our chairs are available with a wide range of color options to fit almost any color plan.
        </h4>
      </div>
    </main>
  );
}

export default AppV2;
