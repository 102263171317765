import React from 'react'
import { Swatch, SwatchImage } from './Swatch'

import './SwatchGroup.css'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class SwatchGroup extends React.Component {
  onSwatchClick(key) {
    this.props.onSwatchChange(key, this.props.name)
  }

  render() {
    return (
      <div>
        <h6 className="swatch-group-label">{capitalizeFirstLetter(this.props.name)}</h6>
        <div className="swatch-group">
          {this.props.swatches.group.map((swatch, key) => {
            return this.props.swatches.type === 'basic' ? (
              <Swatch
                key={key}
                selectedkey={key}
                label={swatch.label}
                // eslint-disable-next-line
                selected={key == this.props.swatches.selected}
                onSwatchClick={this.onSwatchClick.bind(this)}
              />
            ) : (
              <SwatchImage
                key={key}
                option={swatch.option}
                selectedKey={key}
                selected={key === this.props.swatches.selected}
                label={swatch.label}
                onSwatchClick={this.onSwatchClick.bind(this)}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
