import React from 'react';
import BasicPopup from './BasicPopup';
// import SupernovaImage from './July4th.png'
import './SupernovaPopup.css';

import SupernovaChair from './Gray-Poly-Popup3.png';

function SupernovaPopup(props) {
  const onClick = (e) => {
    console.log(props.host);
    window.location.assign(`${props.host}/product?id=PDHCH-CR#PDHCH-CR-T0-A0-RG-GRY`);
  };

  return (
    <BasicPopup
      backgroundColor="#202460"
      textColor="#005FA6"
    >
      <div
        onClick={onClick}
        className="popup-supernova"
      >
        <img width="100%" height="100%" src={SupernovaChair} />
      </div>
    </BasicPopup>
  );
}

export default SupernovaPopup;
