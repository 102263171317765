/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from './Nav';
import AppV2 from './BuildAChair2';
import { Spinning3DChair } from './spinning-3dchair';
import './Products.css';
import Lab from './lab.png';
import Tools from './tools.png';
import Chair360 from './chair-360.png';
import LabChair from './lab-chair.png';
import ControlledChair from './controlled-chair.png';
import IndustrialChair from './industrial-chair.png';
import OfficeChair from './office-chair.png';
import AcademicChair from './academic-chair.png';

function TopBar() {
  return (
    <div className="topbar">
      <h1>Workplace Seating</h1>
      <h3>Ergonomic, American-Made Seating Solutions</h3>
    </div>
  );
}

function LabContainer(props) {
  return (
    <>
      <div className="filter-bound-top lab-landing-nav">
        <Navigation partner={props.partner} url={props.url} />
      </div>
      <div style={{ position: 'relative' }}>
        <img
          className="lab-img"
          src={props.extraAttributes.Image || Lab}
        />
        <div className="overlay-filter" />
        <BuildAChairCard />
      </div>
    </>
  );
}

function BuildAChairCard() {
  return (
    <div
      className="tools"
    >
      <h1>Build a Chair Configurator</h1>
      <img
        className="tools-img"
        src={Tools}
      />
      <a
        className="build-btn"
        href="/chair-guide"
      >
        Start Building!
      </a>
      <h3>Use this app to construct the perfect chair for your workspace & environment, right down to the ideal color!</h3>
    </div>
  );
}

function Chair360CTN() {
  return (
    <div
      className="chair-360"
    >
      <h3>We produce chairs suitable for high-tech laboratory and research settings, including post-Covid workplaces. We offer seating built for the harshest of industrial settings, as well as specialized solutions for clean room and ESD environments.</h3>
      <Spinning3DChair />
    </div>
  );
}

function LabAndHealthcare() {
  return (
    <div
      className="lab-and-healthcare"
    >
      <img src={LabChair} />
      <div>
        <h2>Laboratory & Healthcare</h2>
        <p>Advances in science and medicine begin by taking a seat…on one of our ergonomic laboratory grade chairs. They have been purposely built in the USA to withstand the harsh conditions found in research and healthcare environments.</p>
        <a className="shop-now-btn" href="/laboratory-seating">Shop Now</a>
      </div>
    </div>
  );
}

function ControlledEnvironment() {
  return (
    <div
      className="controlled-environment"
    >
      <h2>Controlled Environment</h2>
      <div className="controlled-environment-inner">
        <div>

          <p style={{ fontSize: '1.2em' }}>
            Our controlled environment seating solutions are manufactured to meet or exceed industry standards for ESD and Clean Room settings.

            These ESD anti-static chairs were developed specifically for the handling of sensitive electronics. They feature proper grounding, conductive, and dissipative static control materials, and have been tested to meet ANSI/ESD-STM-12.1-2013.
          </p>

          <p style={{ fontSize: '1.2em' }}>The Clean Room chairs were designed for applications where the concentration of air-borne particles must be kept below a desired level. They are evaluated by independent laboratories to meet Fed Standard 209E and ISO 14644-1, which is the industry standard for particulate requirements. We offer Clean Room chairs which are certified for ISO Class 4 (Clean Room Class 10) and ISO Class 5 (Clean Room Class 100) standards.</p>
          <a className="shop-now-btn--dark" href="/controlled-environment-seating">Shop Now</a>
        </div>
        <div className="img-container">
          <div>
            <img src={ControlledChair} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Industrial() {
  return (
    <div
      className="lab-and-healthcare"
    >
      <img style={{ transform: 'scale(0.75)' }} src={IndustrialChair} />
      <div>
        <h2>Industrial</h2>
        <p>Harsh conditions require durable seating solutions such as we provide for industrial settings. Our Polyurethane chairs and stools are built to provide ergonomic support in rough environments.</p>
        <a className="shop-now-btn" href="/industrial-seating">Shop Now</a>
      </div>
    </div>
  );
}

function Office() {
  return (
    <div
      className="office-environment"
    >
      <div>
        <h2>Office</h2>
        <p>Both the guest and task style office chairs we produce are not just ergonomically correct, but also aesthetically pleasing. Check out our newest design, the Wendova™ chair featuring a mesh back and a seat upholstered in textured, antibacterial Supernova™ Vinyl.</p>
        <a className="shop-now-btn--dark" href="/office-seating">Shop Now</a>
      </div>
      <div className="img-container">
        <div>
          <img src={OfficeChair} />
        </div>
      </div>
    </div>
  );
}

function Academic() {
  return (
    <div
      className="lab-and-healthcare"
    >
      <img src={AcademicChair} />
      <div>
        <h2>Academic</h2>
        <p>We create seating solutions for educational markets with durability and cleanability in mind. We offer seats in non-porous vinyl covered foam, tough Polyurethane, sturdy wood, and spill resistant plastic. Available with safe, self-braking casters, or stationary glides. (Pictured is our plastic shell chair)</p>
        <a className="shop-now-btn" href="/academic-seating">Shop Now</a>
      </div>
    </div>
  );
}

function AppWrapper(props) {
  return (
    <div
      className="app"
    >
      <iframe className="iFrame" src={`https://buildachair.netlify.app?p=${props.partner}`} height={700} width={1200} />
    </div>
  );
}

export default function Products(props) {
  return (
    <div className="products-container">
      <style>
        {
        `
        .main-nav {
          display: none;
        }
        `
      }
      </style>
      {!props.extraAttributes.NoTopBar && <TopBar />}
      <LabContainer {...props} />
      <Chair360CTN />
      <LabAndHealthcare />
      <ControlledEnvironment />
      <Industrial />
      <Office />
      <Academic />
      <AppWrapper partner={props.partner} />
    </div>
  );
}
