import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
      <path d="M438.07 763.794a329.509 329.509 0 00191.58-61.732l203.136 203.154a48.603 48.603 0 0034.707 14.135c27.867 0 48-21.431 48-48.86 0-12.854-4.28-24.85-13.715-34.267L699.922 633.911c42.423-55.717 67.712-124.709 67.712-199.717 0-181.284-148.279-329.563-329.563-329.563-181.724 0-329.564 148.279-329.564 329.563s147.84 329.6 329.564 329.6zm0-71.15c-141.86 0-258.431-116.991-258.431-258.431 0-141.422 116.571-258.432 258.432-258.432 141.422 0 258.432 117.01 258.432 258.432 0 141.44-117.01 258.432-258.432 258.432z" />
    </svg>
  );
}

export default Icon;
