import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
`

const ImageCanvas = styled.canvas`
  object-fit: scale-down;
  z-index: 101;
`

const closeStyle = {
  top: '20px',
  right: '20px',
  fontSize: '3em',
  position: 'fixed',
  zIndex: '1001',
}

const clamp = (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))

export default class ImageViewer extends React.Component {
  constructor(props) {
    super(props)

    this.scrollGain = 0.5

    this.state = {
      width: 0,
      height: 0,
      containerWidth: 0,
      containerHeight: 0,
      scale: 2,
      zoomed: false,
      translateX: 0,
      translateY: 0,
      image: null,
      prevX: 0,
      prevY: 0,
      showSpinner: true,
    }

    this.onWheel = this.onWheel.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
  }

  componentDidMount() {
    this.refs.imageCanvas
      .addEventListener("wheel", this.onWheel, { passive: false })
    this.refs.imageCanvas
      .addEventListener("touchstart", this.onTouchStart, { passive: false })
    this.refs.imageCanvas
      .addEventListener("touchmove", this.onTouchMove, { passive: false })

    const container = this.refs.container
    this.setState({
      containerWidth: container.offsetWidth,
      containerHeight: container.offsetHeight,
    })

    document.body.style.overflow = 'hidden'

    const img = new Image()
    img.onload = function() {

      this.setState(state => {
        return {
          image: img,
          showSpinner: false,
          width: img.width,
          height: img.height
        }
      }, this.drawBitmap(img))

    }.bind(this)
    img.src = this.props.src
  }

  drawBitmap(bitmap) {
    const container = this.refs.container
    const canvas = this.refs.imageCanvas
    if (canvas !== undefined) {
      const ctx = canvas.getContext('2d')
      canvas.width = bitmap.width
      canvas.height = bitmap.height
      container.style.width = canvas.width
      container.style.height = canvas.height

      var w, h = null

      if (this.state.zoomed === true) {
        w = bitmap.width / this.state.scale
        h = bitmap.height / this.state.scale
      } else {
        if (container.offsetWidth < container.offsetHeight) {
          const scaleFactor = container.offsetWidth / bitmap.width
          w = container.offsetWidth
          h = bitmap.height * scaleFactor

        } else {
          const scaleFactor = container.offsetHeight / bitmap.height
          w = bitmap.width * scaleFactor
          h = container.offsetHeight
        }
      }

      ctx.clearRect(0, 0, container.offsetWidth, container.offsetHeight)

      if (this.state.zoomed === true) {
        const x = (container.offsetWidth - w) / 2
        const y = (container.offsetHeight - h) / 2
        const sw = canvas.width
        const sh = canvas.height
        const sx = bitmap.width / 2 - (sw / 2) - this.state.translateX
        const sy = bitmap.height / 2 - (sh / 2) - this.state.translateY
        ctx.drawImage(bitmap, sx, sy, sw, sh, x + this.state.translateX, y + this.state.translateY, w, h)
      } else {
        const x = (container.offsetWidth - w) / 2
        const y = (container.offsetHeight - h) / 2
        ctx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height, x, y, w, h)
      }
    }
  }

  componentDidUpdate() {
    if (this.state.image !== null) {
      this.drawBitmap(this.state.image)
    }
  }

  componentWillUnmount() {
    this.refs.imageCanvas
      .removeEventListener("wheel", this.onWheel, { passive: false })
    this.refs.imageCanvas
      .removeEventListener("touchstart", this.onTouchStart, { passive: false })
    this.refs.imageCanvas
      .removeEventListener("touchmove", this.onTouchMove, { passive: false })

    document.body.style.overflow = 'scroll'

    this.setState({mounted: false})
  }

  onClick(e) {
    this.setState(state => {
      return {
        zoomed: !state.zoomed,
      }
    })
  }

  onWheel(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.zoomed === true) {

      const h0 = -1 * this.state.image.height / 2
      const h1 = this.state.image.height / 2
      const w0 = -1 * this.state.image.width / 2
      const w1 = this.state.image.width / 2
      const translationY = e.deltaY * this.scrollGain
      const translationX = e.deltaX * this.scrollGain

      this.vScroll(-translationY)
      this.hScroll(-translationX)
    }
  }

  onTouchStart(e) {
    if (e.touches.length > 1) {
      console.log(e.touches)
    } else if (e.touches.length > 0) {
      if (this.state.zoomed === true) {
        const touch = e.touches[0]
        const x = touch.clientX
        const y = touch.clientY
        this.setState({
          prevX: x - this.state.translateX,
          prevY: y - this.state.translateY
        })
      }
    }
  }

  onTouchMove(e) {
    e.preventDefault()
    if (e.touches.length > 1) {
      console.log(e.touches)
    } else if (e.touches.length > 0) {
      if (this.state.zoomed === true) {
        const touch = e.touches[0]
        const clientX = touch.clientX
        const clientY = touch.clientY
        const x = this.state.prevX - clientX
        const y = this.state.prevY - clientY

        this.hScroll(-this.state.translateX - x)
        this.vScroll(-this.state.translateY - y)
      }
    }
  }

  scrollWidth() {
    if (this.state.zoomed === true) {
      return (this.state.containerWidth / (this.state.width)) * 100
    } else {
      return 100
    }
  }

  scrollHeight() {
    if (this.state.zoomed === true) {
      return (this.state.containerHeight / this.state.height) * 100
    } else {
      return 100
    }
  }

  vScroll(delta) {
    this.setState(state => {
      return {
        translateY: clamp(state.translateY + delta, -(this.state.height / this.state.scale / 2), (this.state.height / this.state.scale / 2)),
      }
    })
  }

  hScroll(delta) {
    this.setState(state => {
      return {
        translateX: clamp(state.translateX + delta, -(this.state.width / this.state.scale / 2), (this.state.width / this.state.scale / 2)),
      }
    })
  }

  render() {
    const style = {
      cursor: this.state.zoomed === true ? 'zoom-out' : 'zoom-in'
    }

    const spinnerStyle = {
      position: 'absolute',
      top: 'calc(calc(100vh / 2) - 16px)',
      left: 'calc(calc(100vw / 2) - 16px)',
    }

    const hScroll = this.state.zoomed === true
      ? (this.state.translateX + (1200 / this.state.scale)) / (2400 / this.state.scale) * 100
      : 0

    const vScroll = this.state.zoomed === true
      ? 100 - ((this.state.translateY + (1500 / this.state.scale)) / (3000 / this.state.scale) * 100)
      : 0

    return (
      <Container ref="container">
        { this.state.zoomed === true ?
          <div>
            <ScrollBar
              orientation="vertical"
              innerPercent={this.scrollHeight()}
              scrollPercent={vScroll}
              scroll={this.vScroll.bind(this)}
              length={this.state.containerHeight}
            />
            <ScrollBar
              orientation="horizontal"
              innerPercent={this.scrollWidth()}
              scrollPercent={hScroll}
              scroll={this.hScroll.bind(this)}
              length={this.state.containerWidth}
            />
          </div>
          : ''
        }
        <ImageCanvas
          style={style}
          width={this.state.width + "px"}
          height={this.state.height + "px"}
          ref="imageCanvas"
          id="imageCanvas"
          onClick={this.onClick.bind(this)}
        />
        { this.state.showSpinner === true ?
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
          style={spinnerStyle}
        />
        : ''
        }
        <button
          style={closeStyle}
          type="button"
          className="close"
          aria-label="Close"
          onClick={this.props.onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Container>
    )
  }
}

const ScrollContainer = styled.div`
  @media screen and (max-width: 768px) {
    visibility: hidden;
  }
`

class ScrollBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mouseDown: false,
      mousePosition: 0,
    }
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onMouseUp.bind(this), true)
    document.addEventListener('mousemove', this.onMouseMove.bind(this), true)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onMouseUp.bind(this), true)
    document.removeEventListener('mousemove', this.onMouseMove.bind(this), true)
  }

  onMouseDown = (e) => {
    e.preventDefault()

    if (this.props.orientation === 'vertical') {
      this.setState({
        mouseDown: true,
        mousePosition: e.clientY,
      })
    } else if (this.props.orientation === 'horizontal') {
      this.setState({
        mouseDown: true,
        mousePosition: e.clientX,
      })
    }
  }

  onMouseMove = (e) => {
    if (this.state.mouseDown === true) {
      if (this.props.orientation === 'vertical') {
        const diff = (this.state.mousePosition - e.clientY)
        this.props.scroll(diff)
        this.setState({mousePosition: e.clientY})
      } else if (this.props.orientation === 'horizontal') {
        const diff = (this.state.mousePosition - e.clientX)
        this.props.scroll(diff)
        this.setState({mousePosition: e.clientX})
      }
    }
  }

  onMouseUp = (e) => {
    this.setState({mouseDown: false})
  }

  render() {
    let baseStyle = {}
    let insetStyle = {}

    if (this.props.orientation === 'vertical') {
      baseStyle = {
        position: "fixed",
        height: 'calc(100vh - 15px)',
        background: '#AAA',
        width: '15px',
        right: '0px',
        opacity: 0.75,
      }

      insetStyle = {
        position: 'absolute',
        background: '#111',
        width: '80%',
        height: `${this.props.innerPercent}%`,
        top: `${this.props.scrollPercent - (this.props.innerPercent / 2)}%`,
        margin: '2px',
      }

    } else if (this.props.orientation === 'horizontal') {
      baseStyle = {
        position: "fixed",
        height: '15px',
        background: '#AAA',
        width: '100vw',
        bottom: '0px',
        opacity: 0.75,
      }

      insetStyle = {
        position: 'absolute',
        background: '#111',
        height: '80%',
        width: `${this.props.innerPercent}%`,
        margin: '2px',
        right: `${this.props.scrollPercent - (this.props.innerPercent / 2)}%`,
      }
    }

    return (
      <ScrollContainer
        onMouseUp={this.onMouseUp.bind(this)}
        onMouseMove={this.onMouseMove.bind(this)}
        style={baseStyle}
      >
        <div
          style={insetStyle}
          onMouseDown={this.onMouseDown.bind(this)}
          onMouseMove={this.onMouseMove.bind(this)}
          onMouseUp={this.onMouseUp.bind(this)}
        ></div>
      </ScrollContainer>
    )
  }
}

