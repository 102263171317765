/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './spinning-3dchair.css';

const url = 'https://ecomseating.s3.amazonaws.com/images/';

export function Spinning3DChair() {
  const [top, setTop] = React.useState(0);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [dragging, setDragging] = React.useState(false);
  const [lastDeltaX, setLastDeltaX] = React.useState(null);
  const [lastDeltaTime, setLastDeltaTime] = React.useState(null);
  const [spinningTimerCount, setSpinningTimerCount] = React.useState(0);
  const [diffX, setDiffX] = React.useState(null);
  const [imageWidth, setImageWidth] = React.useState(window.innerWidth >= 1000 ? 900 : window.innerWidth * 0.75);
  const [imageHeight, setImageHeight] = React.useState(window.innerWidth >= 1000 ? 600 : window.innerWidth * 0.75);
  const canvasRef = React.useRef(null);
  const [images, setImages] = React.useState([]);
  let debounceCount = 0;
  const [timerHandle, setTimerHandle] = React.useState(null);
  let spinningTick = 1;
  let clockwise = true;

  React.useEffect(() => {
    for (const index in [...Array(35).keys()]) {
      const key = parseInt(index) + 1;
      const img = document.createElement('img');
      img.src = formatURL(key);
      img.height = imageHeight;
      img.width = imageWidth;
      img.id = key;
      const localImages = images;
      localImages.push(img);
      setImages(localImages);

      if (key == 1) {
        const ctx = canvasRef.current.getContext('2d');
        if (ctx) {
          img.onload = function () {
            ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
          };
        }
      }
    }

    window.addEventListener('resize', (e) => {
      setWidth(window.innerWidth);
      if (window.innerWidth > 1000) {
        setImageWidth(900);
        setImageHeight(600);
      } else {
        setImageWidth(window.innerWidth * 0.75);
        setImageHeight(window.innerWidth * 0.75);
      }
    });

    window.addEventListener('mouseup', (e) => {
      onMouseUp(e);
    });

    window.addEventListener('mousemove', onMouseDrag);

    startAutoSpin();

    return () => {
      window.removeEventListener('mousemove', onMouseDrag);
    };
  }, []);

  React.useEffect(() => {
    const localImages = [];
    for (const index in [...Array(35).keys()]) {
      const key = parseInt(index) + 1;
      const img = document.createElement('img');
      img.src = formatURL(key);
      img.height = imageHeight;
      img.width = imageWidth;
      img.id = key;
      localImages.push(img);

      if (key == 1) {
        const ctx = canvasRef.current.getContext('2d');
        if (ctx) {
          img.onload = function () {
            ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
          };
        }
      }
    }
    setImages(localImages);
  }, [width]);

  React.useEffect(() => {
    const img = images[top];
    if (img) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, imageWidth, imageHeight);
        ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
      }
    }
  }, [top]);

  const timerHandler = () => {
    spin(true);
  };

  const determineFolder = () => (width >= 1000 ? '360_annotated/' : '360/');

  const getExtension = () => {
    if (determineFolder() == '360/') {
      return '.jpg';
    }
    return '.png';
  };

  const formatURL = (value) => {
    if (value < 10) {
      return `${url + determineFolder()}img0${value.toString()}${getExtension()}`;
    }
    return `${url + determineFolder()}img${value.toString()}${getExtension()}`;
  };

  const debounce = (e) => {
    if (debounceCount < 4) {
      debounceCount += 1;
    } else {
      execMouseDrag(e);
      debounceCount = 0;
    }
  };

  const execMouseDrag = (e) => {
    if (dragging) {
      const x = e.touches?.length > 0 ? e.touches[0].clientX : e.clientX;
      spin(x - lastDeltaX < diffX);
      setDiffX(x - lastDeltaX);
    }
  };

  const spin = () => {
    setTop((top) => {
      if (top >= 34) {
        return clockwise ? 0 : top - 1;
      } if (top == 0) {
        return clockwise ? top + 1 : 34;
      }
      return clockwise ? top + 1 : top - 1;
    });
  };

  const onMouseDrag = (e) => {
    if (lastDeltaX != null) {
      const x = e.touches?.length > 0 ? e.touches[0].clientX : e.clientX;
      clockwise = x - lastDeltaX < diffX;
      debounce(e);
    }
  };

  const onMouseDown = (e) => {
    clearTimeout(timerHandle);
    setTimerHandle(null);

    setLastDeltaX(e.touches?.length > 0 ? e.touches[0].clientX : e.clientX);
    setLastDeltaTime(e.timeStamp);
    setDragging(true);
  };

  const startAutoSpin = () => {
    setTimerHandle((timerHandle) => {
      if (timerHandle == null) {
        return setInterval(timerHandler, 100);
      }
      return timerHandle;
    });
  };

  const setSpinTimer = (delay) => {
    console.log({ delay });
    setTimeout(() => {
      spin();
      spinningTick += 1;
      if (delay < 100) {
        setSpinTimer(delay * 1.001 ** spinningTick);
        console.log(delay * 1.001 ** spinningTick);
      } else {
        spinningTick = 1;
        setSpinningTimerCount((spinningTimerCount) => (spinningTimerCount > 0 ? spinningTimerCount - 1 : 0));
      }
    }, delay);
  };

  const onMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const x = e.touches?.length > 0 ? e.touches[0].clientX : e.clientX;
    const velocityThreshold = 1;
    clockwise = x < lastDeltaX;
    const velocity = Math.abs(x - lastDeltaX) / Math.abs(e.timeStamp - lastDeltaTime);
    if (velocity > velocityThreshold) {
      setSpinTimer(1 / velocity);
    } else {
      // startAutoSpin()
    }

    setLastDeltaX(null);
    setLastDeltaTime(null);
  };

  return (
    <div
      className="spinning-3dchair-container"
      onMouseMove={onMouseDrag}
      onMouseUp={onMouseUp}
    >
      <canvas
        onTouchStart={onMouseDown}
        onTouchMove={onMouseDrag}
        onTouchEnd={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseDrag}
        height={imageHeight}
        width={imageWidth}
        ref={canvasRef}
      />
      <span style={{ color: '#333', fontSize: '24px' }}>Drag to spin!</span>
    </div>
  );
}
