import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="25.641"
      viewBox="0 0 40 25.641"
    >
      <path
        fill="#fff"
        d="M6.627 18.166v25.641h40V18.166h-40zm14.289 24.872H7.4v-24.1h38.457v14.686a86.814 86.814 0 01-24.942 9.414zM9.877 25.964H8.165v6h1.712a3.1 3.1 0 002.145-.685 3.024 3.024 0 001.078-2.305 2.985 2.985 0 00-3.226-3.009zm1.37 4.524a2.249 2.249 0 01-1.6.469h-.311v-3.965h.306a2.119 2.119 0 011.6.487 1.986 1.986 0 01.649 1.5 2.055 2.055 0 01-.644 1.509zm2.4-4.524h1.171v6h-1.174zm4.037 2.307c-.7-.252-.9-.432-.9-.757 0-.378.361-.667.865-.667a1.216 1.216 0 01.955.487l.613-.793a2.6 2.6 0 00-1.766-.667 1.764 1.764 0 00-1.874 1.712c0 .829.378 1.262 1.478 1.658a3.978 3.978 0 01.811.343.686.686 0 01.343.613.849.849 0 01-.9.847 1.378 1.378 0 01-1.28-.793l-.757.721a2.342 2.342 0 002.073 1.153 1.936 1.936 0 002.073-1.983c0-.955-.4-1.388-1.731-1.875zm2.109.7a3.113 3.113 0 003.172 3.136 3.144 3.144 0 001.46-.343V30.38a1.882 1.882 0 01-1.406.667A1.964 1.964 0 0121 28.974a2.006 2.006 0 011.962-2.074 1.921 1.921 0 011.46.685V26.2a3.052 3.052 0 00-1.442-.361 3.15 3.15 0 00-3.19 3.136zm11.5.036a3.262 3.262 0 11-3.262-3.262 3.262 3.262 0 013.262 3.262zm2.429.993l-1.6-4.037h-1.28l2.558 6.165h.631l2.595-6.164h-1.282zm3.425 1.965h3.316v-1.008h-2.145v-1.622h2.073v-1.01h-2.073v-1.333h2.145v-1.028h-3.316zm6.6-2.523a1.59 1.59 0 001.37-1.694c0-1.135-.775-1.785-2.127-1.785h-1.752v6h1.171v-2.4h.163l1.622 2.4h1.441l-1.892-2.523zm-.992-.7h-.343V26.92h.36c.739 0 1.135.306 1.135.883 0 .613-.4.937-1.153.937z"
        data-name="Icon payment-discover"
        transform="translate(-6.627 -18.166)"
      />
    </svg>
  );
}

export default Icon;
