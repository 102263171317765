import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M0 0H35V35H0z"
            data-name="Rectangle 20"
            transform="translate(954 105)"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-path)"
        data-name="Mask Group 2"
        transform="translate(-954 -105)"
      >
        <g transform="translate(954 105)">
          <path
            d="M17.5 17.5a5.833 5.833 0 10-5.833-5.833A5.832 5.832 0 0017.5 17.5zm0 2.917c-3.894 0-11.667 1.954-11.667 5.833v2.917h23.334V26.25c0-3.879-7.773-5.833-11.667-5.833z"
            data-name="Path 3"
          />
          <path fill="none" d="M0 0h35v35H0z" data-name="Path 4" />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
