/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import Phone from './component/phoneIcon';
import Account from './component/account';
import Cart from './component/checkoutCart';
import Recent from './component/recent';
import Search from './component/search';
import logoImg from './emerald-logo.png';

function Header() {
  return (
    <header className="header" role="banner">
      <a
        href="#"
        className="mobileMenu-toggle"
        data-mobile-menu-toggle="menu"
        aria-controls="menu"
        aria-expanded="false"
      >
        <span className="mobileMenu-toggleIcon">Toggle menu</span>
      </a>
      <div className="emthemesModez-header-topSection">
        <div className="container">
          <nav className="navUser navUser--storeInfo">
            <ul className="navUser-section">
              <li className="navUser-item">
                <span>
                  The single-source for all of your cannabis science product and
                  service needs.
                </span>
              </li>
            </ul>
          </nav>
          <nav className="navUser">
            <ul className="navUser-section">
              <li className="navUser-item">
                <a className="navUser-action top-action" href="tel:1-877-567-3598">
                  <div className="icon top-icon">
                    <Phone />
                  </div>
                  <span>1-877-567-3598</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        className="emthemesModez-header-userSection emthemesModez-header-userSection--logo-left"
        data-stickymenu=""
      >
        <div className="container">
          <nav className="navUser">
            <ul className="navUser-section navUser-section--alt">
              <li className="navUser-item">
                <a
                  id="validate-company"
                  className="navUser-action c0030"
                  href="https://emeraldscientific.com/login.php?action=create_account&show_tpa"
                >
                  Trade Professional Application
                </a>
              </li>
              <li className="navUser-item navUser-item--account">
                <span className="icon">
                  <Account />
                </span>
                <a className="navUser-action" href="https://emeraldscientific.com/login.php">
                  <span className="navUser-label">Sign in</span>
                </a>
                <span className="navUser-or">/</span>
                <a className="navUser-action" href="https://emeraldscientific.com/register-for-new-account/">
                  Register
                </a>
              </li>
              <li className="navUser-item navUser-item--shopping">
                <a className="navUser-action" href="https://emeraldscientific.com/shopping-lists/">
                  <span className="icon">
                    <Cart />
                  </span>
                  <span className="navUser-label">
                    My
                    {' '}
                    <br />
                    Lists
                  </span>
                </a>
              </li>
              <li className="navUser-item navUser-item--recentView">
                <a className="navUser-action" href="https://emeraldscientific.com/login.php">
                  <span className="icon">
                    <Recent />
                  </span>
                  <span className="navUser-label">
                    Recently
                    {' '}
                    <br />
                    Viewed
                  </span>
                </a>
              </li>
              <li className="navUser-item navUser-item--compare">
                <a
                  className="navUser-action navUser-action--compare"
                  href="https://emeraldscientific.com/compare"
                  data-compare-nav=""
                >
                  Compare
                  {' '}
                  <span className="countPill countPill--positive countPill--alt" />
                </a>
              </li>
              <li className="navUser-item navUser-item--cart">
                <a
                  data-cart-preview=""
                  data-dropdown="cart-preview-dropdown"
                  className="navUser-action navUser-action--cart"
                  href="https://emeraldscientific.com/cart.php"
                  title="Cart"
                >
                  <span className="icon">
                    <svg>
                      <use xlinkHref="#icon-mini-cart" />
                    </svg>
                  </span>
                  <span className="navUser-label">
                    <span className="countPill cart-quantity">0</span>
                    <span
                      className="navUser-item-cartLabel"
                      style={{ textAlign: 'center' }}
                    >
                      Cart
                    </span>
                  </span>
                </a>
                <div
                  className="dropdown-menu"
                  id="cart-preview-dropdown"
                  data-dropdown-content=""
                  aria-hidden="true"
                  aria-autoclose="false"
                />
              </li>
            </ul>
          </nav>
          <h1 className="header-logo header-logo--left">
            <a href="https://emeraldscientific.com/">
              <div className="header-logo-image-container">
                <img
                  className="header-logo-image"
                  src={logoImg}
                  alt="NEW Emerald Scientific"
                />
              </div>
            </a>
          </h1>
          <div
            className="emthemesModez-quickSearch"
            data-prevent-quick-search-close=""
          >
            {/* snippet location forms_search */}
            <form className="form" action="https://emeraldscientific.com/search.php">
              <fieldset className="form-fieldset">
                <div className="form-field">
                  <label className="is-srOnly" htmlFor="search_query">
                    Search
                  </label>
                  <div className="form-prefixPostfix wrap">
                    <input
                      className="form-input"
                      data-search-quick=""
                      name="search_query"
                      id="search_query"
                      data-error-message="Search field cannot be empty."
                      placeholder="Search the store"
                      autoComplete="off"
                    />
                    <button
                      type="submit"
                      className="button button--primary form-prefixPostfix-button--postfix"
                    >
                      <span>Search</span>
                      <Search />
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <div
        className="papathemes-stickymenu-placeholder"
        style={{ height: '84.2812px' }}
      />
      <div
        className="navPages-container navPages-container--bg"
        id="menu"
        data-menu=""
        aria-hidden="true"
      >
        <div className="container">
          <nav className="navPages">
            <ul className="navPages-list">
              <li className="navPages-item">
                <a
                  className="navPages-action"
                  href="https://emeraldscientific.com/contact-us/"
                >
                  Contact Us
                </a>
              </li>
              <li className="navPages-item">
                <a
                  className="navPages-action"
                  href="https://pt.emeraldscientific.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Emerald Test™
                </a>
              </li>
              <li className="navPages-item">
                <a
                  className="navPages-action"
                  href="https://community.emeraldscientific.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Emerald Community
                </a>
              </li>
              <li className="navPages-item">
                <a className="navPages-action" href="https://emeraldscientific.com/spex-certiprep">
                  Spex
                </a>
              </li>
              <li className="navPages-item">
                <a
                  className="navPages-action has-subMenu"
                  href="https://emeraldscientific.com/about-emerald/"
                  data-collapsible="navPages-page-4"
                  data-collapsible-hover-open="true"
                  aria-controls="navPages-page-4"
                  aria-expanded="false"
                >
                  <span>About Emerald</span>
                </a>
              </li>
              <li className="navPages-item">
                <a
                  className="navPages-action"
                  href="https://community.emeraldscientific.com/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
          </nav>
          {' '}
        </div>
      </div>
    </header>

  );
}

export default Header;
