import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#00778b"
            d="M0 0H35V35H0z"
            data-name="Rectangle 210"
            transform="translate(1228 105)"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-path)"
        data-name="Mask Group 45"
        transform="translate(-1228 -105)"
      >
        <g transform="translate(1228 105)">
          <path
            fill="#545454"
            d="M10.208 26.25a2.917 2.917 0 102.917 2.917 2.913 2.913 0 00-2.917-2.917zM1.458 2.917v2.916h2.917L9.625 16.9l-1.969 3.575a2.82 2.82 0 00-.365 1.4 2.925 2.925 0 002.917 2.917h17.5v-2.917H10.821a.361.361 0 01-.365-.365l.044-.175 1.313-2.377h10.864a2.9 2.9 0 002.552-1.5l5.221-9.466a1.425 1.425 0 00.175-.7 1.463 1.463 0 00-1.458-1.458H7.6L6.227 2.917H1.458zM24.792 26.25a2.917 2.917 0 102.917 2.917 2.913 2.913 0 00-2.917-2.917z"
            data-name="Path 82"
          />
          <path fill="none" d="M0 0h35v35H0z" data-name="Path 83" />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
