import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M0 0H35V35H0z"
            data-name="Rectangle 18"
            transform="translate(1236 105)"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-path)"
        data-name="Mask Group 20"
        transform="translate(-1236 -105)"
      >
        <g transform="translate(1236 105)">
          <path fill="none" d="M0 0h35v35H0z" data-name="Path 44" />
          <path
            d="M18.958 4.375A13.126 13.126 0 005.833 17.5H1.458l5.673 5.673.1.2 5.894-5.873H8.75a10.257 10.257 0 113 7.2l-2.067 2.075a13.122 13.122 0 109.275-22.4zM17.5 11.667v7.292l6.242 3.7 1.05-1.765-5.1-3.033v-6.2z"
            data-name="Path 45"
          />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
