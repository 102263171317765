import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g data-name="Group 442" transform="translate(-888 -107)">
        <circle
          cx="18"
          cy="18"
          r="18"
          data-name="Ellipse 6"
          transform="translate(888 107)"
        ></circle>
        <path
          fill="#fff"
          d="M9.581 9.563l.472-3.077H7.1v-2a1.538 1.538 0 011.736-1.659h1.342V.208A16.366 16.366 0 007.8 0C5.369 0 3.78 1.474 3.78 4.141v2.345h-2.7v3.077h2.7V17H7.1V9.563z"
          data-name="Icon awesome-facebook-f"
          transform="translate(899.927 117)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;