import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M0 0H16V16H0z"
            data-name="Rectangle 52"
            transform="translate(1084 3350)"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-path)"
        data-name="Mask Group 18"
        transform="translate(-1084 -3350)"
      >
        <g data-name="Map Pin" transform="translate(1084 3350)">
          <path
            d="M8 1.333A4.663 4.663 0 003.333 6C3.333 9.5 8 14.667 8 14.667S12.667 9.5 12.667 6A4.663 4.663 0 008 1.333zm0 6.334A1.667 1.667 0 119.667 6 1.667 1.667 0 018 7.667z"
            data-name="Path 35"
          />
          <path fill="none" d="M0 0h16v16H0z" data-name="Path 36" />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
