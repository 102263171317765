import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      viewBox="0 0 15 12"
    >
      <path
        d="M15 3H3a1.5 1.5 0 00-1.492 1.5l-.008 9A1.5 1.5 0 003 15h12a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0015 3zm0 3L9 9.75 3 6V4.5l6 3.75 6-3.75z"
        data-name="Icon material-email"
        transform="translate(-1.5 -3)"
      />
    </svg>
  );
}

export default Icon;
