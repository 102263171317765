/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Router from '../../catalog/Router';
import { Navigation } from '../../catalog/Nav';
import Header from './Header';
import Footer from './Footer';
import SupernovaPopup from '../../catalog/SupernovaPopup';
import Favicon from './favicon.png';
import setFavicons from '../../catalog/SetFavicons';

import './App.css';

export default function App(props) {
  const title = 'Emerald';
  const catalog = 'partner';
  const partner = 'emerald';
  const logoURL = 'https://ecomseating.s3.amazonaws.com/images/other/emerald-logo.png';
  const buildAChair = 'emerald';
  setFavicons(Favicon);
  const extraAttributes = {
    trackId: 'G-JXSNBGLXJD',
  };

  ReactGA.initialize(extraAttributes.trackId);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  document.title = title;
  document.partner = partner;
  document.catalog = catalog;
  document.buildAChair = buildAChair;

  return (
    <div>
      <Header />
      <div className="center-container">
        <div className="filter-bound-top main-nav">
          <Navigation partner={partner} url={logoURL} />
        </div>
        <Router
          partner={partner}
          catalog={catalog}
          orderButtonType="rfq"
          logoURL={logoURL}
          extraAttributes={extraAttributes}
        />
      </div>
      <Footer className="filter-bound-bottom" />
      <SupernovaPopup
        host="https://seating.emeraldscientific.com"
        backgroundColor="white"
        textColor="#0052A7"
      />
    </div>
  );
}
