// We need to do param reassignment because not all apps have extra attributes.
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-constructor */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import Products from './Products';
import ProductPage from './ProductPage';
import './Router.css';

const ProductsPageWrapper = React.lazy(() => import('./ProductsPageWrapper'));
const ProductsPageSearchWrapper = React.lazy(() => import('./ProductsPageSearchWrapper'));
const LaboratorySeating = React.lazy(() => import('./LaboratorySeating'));
const OfficeSeating = React.lazy(() => import('./OfficeSeating'));
const MedicalSeating = React.lazy(() => import('./MedicalSeating'));
const IndustrialSeating = React.lazy(() => import('./IndustrialSeating'));
const ControlledEnvironmentSeating = React.lazy(() => import('./ControlledEnvironmentSeating'));
const RequestQuote = React.lazy(() => import('./RequestQuote'));
const RequestQuoteSuccess = React.lazy(() => import('./RequestQuoteSuccess'));
const ChairGuide = React.lazy(() => import('./BuildAChair'));
const ChairGuide2 = React.lazy(() => import('./BuildAChair2'));
const SupernovaInfo = React.lazy(() => import('./SupernovaInfo'));

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extraAttributes: props.extraAttributes,
    };
  }

  componentDidMount() {
    if (document.referrer && window.parent) {
      setTimeout(() => {
        const message = document.body.scrollHeight;
        window.parent.postMessage(message, `${document.referrer}`);
        console.log(`handler ${message}`);
      }, 1000);
    }
    if (document.referrer && window.parent) {
      const handler = async () => {
        const message = document.body.scrollHeight;
        window.parent.postMessage(message, `${document.referrer}`);
        console.log(`handler ${message}`);
      };
      window.addEventListener('load', handler, false);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/chair-guide">
            <ChairGuide2 folder={document.partner} partner={this.props.partner} />
          </Route>

          <Route path="/supernova-info">
            <SupernovaInfo />
          </Route>
          <Route path="/product">
            <ProductPage
              orderButtonType={this.props.orderButtonType}
              catalog={this.props.catalog}
              logoURL={this.props.logoURL}
              partner={this.props.partner}
            />
          </Route>
          <Route path="/search">
            <ProductsPageSearchWrapper catalog={this.props.catalog} extraAttributes={this.state.extraAttributes} />
          </Route>
          <Route path="/laboratory-seating/chairs">
            <ProductsPageWrapper
              category="laboratory-seating"
              subCategory="chairs"
              categoryTitle="Laboratory Chairs"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}

            />
          </Route>
          <Route path="/laboratory-seating/stools">
            <ProductsPageWrapper
              category="laboratory-seating"
              subCategory="stools"
              categoryTitle="Laboratory Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/laboratory-seating/stools-with-backs">
            <ProductsPageWrapper
              category="laboratory-seating"
              subCategory="stools-with-backs"
              categoryTitle="Laboratory Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/laboratory-seating/speciality">
            <ProductsPageWrapper
              category="laboratory-seating"
              subCategory="speciality-seating"
              categoryTitle="Laboratory Speciality"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/laboratory-seating">
            <LaboratorySeating />
          </Route>
          <Route path="/office-seating/chairs">
            <ProductsPageWrapper
              category="office-seating"
              subCategory="chairs"
              categoryTitle="Office Chairs"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/office-seating/stools">
            <ProductsPageWrapper
              category="office-seating"
              subCategory="stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/office-seating/stools-with-backs">
            <ProductsPageWrapper
              category="office-seating"
              subCategory="stools-with-backs"
              categoryTitle="Office Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/office-seating/speciality">
            <ProductsPageWrapper
              category="office-seating"
              subCategory="speciality-seating"
              categoryTitle="Office Speciality"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/office-seating">
            <OfficeSeating />
          </Route>
          <Route path="/industrial-seating/chairs">
            <ProductsPageWrapper
              category="industrial-seating"
              subCategory="chairs"
              categoryTitle="Industrial Chairs"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/industrial-seating/stools">
            <ProductsPageWrapper
              category="industrial-seating"
              subCategory="stools"
              categoryTitle="Industrial Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/industrial-seating/stools-with-backs">
            <ProductsPageWrapper
              category="industrial-seating"
              subCategory="stools-with-backs"
              categoryTitle="Industrial Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/industrial-seating/speciality">
            <ProductsPageWrapper
              category="industrial-seating"
              subCategory="speciality-seating"
              categoryTitle="Industrial Speciality"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/industrial-seating">
            <IndustrialSeating />
          </Route>
          <Route path="/medical-seating/chairs">
            <ProductsPageWrapper
              category="medical-seating"
              subCategory="chairs"
              categoryTitle="Medical Chairs"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/medical-seating/stools">
            <ProductsPageWrapper
              category="medical-seating"
              subCategory="stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/medical-seating/stools-with-backs">
            <ProductsPageWrapper
              category="medical-seating"
              subCategory="stools-with-backs"
              categoryTitle="Medical Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/medical-seating/speciality">
            <ProductsPageWrapper
              category="medical-seating"
              subCategory="speciality-seating"
              categoryTitle="Medical Speciality"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/medical-seating">
            <MedicalSeating />
          </Route>
          <Route path="/controlled-environment-seating/esd-chairs">
            <ProductsPageWrapper
              category="controlled-environment-seating"
              subCategory="esd-chairs"
              categoryTitle="ESD Chairs"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/controlled-environment-seating/esd-stools">
            <ProductsPageWrapper
              category="controlled-environment-seating"
              subCategory="esd-stools"
              categoryTitle="ESD Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/controlled-environment-seating/cleanroom-stools">
            <ProductsPageWrapper
              category="controlled-environment-seating"
              subCategory="cleanroom-stools"
              categoryTitle="Clean Room Stools"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/controlled-environment-seating/cleanroom-chairs">
            <ProductsPageWrapper
              category="controlled-environment-seating"
              subCategory="cleanroom-chairs"
              categoryTitle="Clean Room Chairs"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/controlled-environment-seating/esd-cleanroom-chairs">
            <ProductsPageWrapper
              category="controlled-environment-seating"
              categoryTitle="ESD & Clean Room Chairs"
              subCategory="esd-cleanroom-chairs"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/controlled-environment-seating">
            <ControlledEnvironmentSeating />
          </Route>
          <Route path="/quote-success">
            {this.props.quoteSuccess}
          </Route>
          <Route path="/quote">
            <RequestQuote partner={this.props.partner} />
          </Route>
          <Route path="/new-products">
            <ProductsPageWrapper
              category="new-products"
              categoryTitle="New Products"
              subCategory="new-products"
              catalog={this.props.catalog}
              partner={this.props.partner}
              extraAttributes={this.state.extraAttributes}
            />
          </Route>
          <Route path="/">
            {React.cloneElement(this.props.home, { partner: this.props.partner, url: this.props.logoURL, extraAttributes: this.state.extraAttributes })}
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.defaultProps = {
  home: <Products />,
  quoteSuccess: <RequestQuoteSuccess />,
};

export default Router;
