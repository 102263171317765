const SetFavicons = (favImg) => {
  let headTitle = document.querySelector('head')
  let setFavicon = document.createElement('link')
  setFavicon.setAttribute('rel', 'icon')
  setFavicon.setAttribute('href', favImg)
  setFavicon.setAttribute('type', 'image/x-icon')
  headTitle.appendChild(setFavicon)
}

export default SetFavicons
