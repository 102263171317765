/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import './BasicPopup.css';

function BasicPopup(props) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (document.referrer.includes(window.location.host)) {
      setOpen(false);
    }
  });

  return (
    <div
      className="popup"
      style={{
        bottom: '-500px',
        display: open === true ? 'block' : 'none',
      }}
    >
      <div className="popup-close-container">
        <div
          className="popup-close"
          style={{
            background: props.backgroundColor,
            color: 'white',
          }}
          onClick={(e) => setOpen(false)}
        >
          <span
            style={{
              display: 'flex',
              alignCenter: 'center',
              justifyContent: 'center',
            }}
            onClick={(e) => setOpen(false)}
          >
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </span>
        </div>
      </div>
      <div
        className="popup-card"
        style={{
          background: props.backgroundColor,
          color: props.textColor,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default BasicPopup;
