import React, { useEffect } from 'react';
import logoImg from './emerald-logo.png';
import unique from 'unique-selector';
import LinkedIn from './component/linkedin';
import Facebook from './component/facebook';
import Twitter from './component/twitter';
import Location from './component/location';
import Email from './component/email';
import LogoAE from './component/logoae';
import LogoDiscover from './component/logodiscover';
import LogoMC from './component/logomc';
import LogoVisa from './component/logovisa';
import PhoneIcon from './component/phoneIcon';
/* eslint-disable react/jsx-filename-extension */

function Footer() {
  return (
    <footer className="footer" role="contentinfo">
      <div data-content-region="newsletter_below" />
      <div data-content-region="newsletter_below--global" />
      <div className="container">
        <section className="footer-info">
          <article
            className="footer-info-col footer-info-col--about"
            data-section-type="storeInfo"
          >
            <div data-content-region="footer_before_logo--global" />
            <div className="footer-info-heading">
              <div className="footer-logo">
                <a href="https://emeraldscientific.com/">
                  <div className="header-logo-image-container">
                    <img
                      className="header-logo-image"
                      src={logoImg}
                      srcSet="https://cdn11.bigcommerce.com/s-txfdoiqbx5/images/stencil/250x50/2023-03-01_newbclogo-01__1680070449.original.png 1x, https://cdn11.bigcommerce.com/s-txfdoiqbx5/images/stencil/500w/2023-03-01_newbclogo-01__1680070449.original.png 2x"
                      alt="NEW Emerald Scientific"
                      title="NEW Emerald Scientific"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div data-content-region="footer_below_logo--global" />
            <ul className="fa-ul">
              <li>
                <i className="icon">
                  <Location />
                </i>
                <address>
                  <a
                    href="https://www.google.com/maps/place/11573+Los+Osos+Valley+Rd,+San+Luis+Obispo,+CA+93405,+USA/@35.256961,-120.6936791,17z/data=!3m1!4b1!4m5!3m4!1s0x80ecf0bfae792fbf:0x16b9daf0ff0f0eaa!8m2!3d35.256961!4d-120.6914904"
                    target="_blank"
                    rel="noreferrer"
                  >
                    11573 Los Osos Valley Road
                    <br />
                    San Luis Obispo, CA 93405
                  </a>
                </address>
              </li>
              <li>
                <i className="icon">
                  <PhoneIcon />
                </i>
                <a href="tel:1-877-567-3598">1-877-567-3598</a>
              </li>
              <li className="footer-email">
                <i className="icon">
                  <Email />
                </i>
              </li>
            </ul>
            <div data-content-region="footer_below_contact--global" />
          </article>
          <article className="footer-info-col" data-section-type="footer-webPages">
            <h3 className="footer-info-heading">Accounts &amp; Orders</h3>
            <ul className="footer-info-list">
              <li>
                <a href="https://emeraldscientific.com/shopping-lists/">My Lists</a>
              </li>
              <li>
                <a href="https://emeraldscientific.com/account.php">My Account</a>
              </li>
              <li>
                <a href="https://emeraldscientific.com/login.php?action=logout">Log Out</a>
              </li>
              <li>
                <a href="https://emeraldscientific.com/account.php?action=order_status">Order Status</a>
              </li>
              <li>
                <a href="https://emeraldscientific.com/shipping-returns/">
                  Shipping &amp; Returns
                </a>
              </li>
              {/* <li>
                      <a href="/sitemap.php">Sitemap</a>
                  </li> */}
            </ul>
            <div data-content-region="footer_below_pages--global" />
          </article>
          <article
            className="footer-info-col"
            data-section-type="footer-categories"
          >
            <h3 className="footer-info-heading">Quick Links</h3>
            <ul className="footer-info-list">
              <li>
                <a href="https://emeraldscientific.com/contact-us/">Contact Us</a>
              </li>
              <li>
                <a href="https://pt.emeraldscientific.com/" target="_blank" rel="noreferrer">
                  The Emerald Test™
                </a>
              </li>
              <li>
                <a href="https://community.emeraldscientific.com/" target="_blank" rel="noreferrer">
                  Emerald Community
                </a>
              </li>
              <li>
                <a href="https://emeraldscientific.com/spex-certiprep">Spex</a>
              </li>
              <li>
                <a href="https://emeraldscientific.com/about-emerald/">
                  About Emerald
                </a>
              </li>
              <li>
                <a
                  href="https://community.emeraldscientific.com/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
            <div data-content-region="footer_below_categories--global" />
          </article>
          <article
            className="footer-info-col"
            data-section-type="footer-categories"
          >
            <h3 className="footer-info-heading">Connect With Us</h3>
            <ul className="socialLinks ">
              <li className="socialLinks-item">
                <a
                  className="icon icon--linkedin"
                  href="https://www.linkedin.com/company/emerald-scientific"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn />
                </a>
              </li>
              <li className="socialLinks-item">
                <a
                  className="icon icon--facebook"
                  href="https://www.facebook.com/emeraldscientific"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </a>
              </li>
              <li className="socialLinks-item">
                <a
                  className="icon icon--twitter"
                  href="https://twitter.com/EmeraldSci"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter />
                </a>
              </li>
            </ul>
          </article>
        </section>
        <div data-content-region="footer_below_columns--global" />
      </div>
      <div className="footer-copyright">
        <div className="container">
          <p className="powered-by">
            ®
            {' '}
            <span id="copyright_year">2023</span>
            {' '}
            Emerald Scientific |
            {' '}
            <a href="https://emeraldscientific.com/sitemap.php">Sitemap</a>
          </p>
          <div className="footer-payment-icons">
            <LogoAE />
            <LogoDiscover />
            <LogoMC />
            <LogoVisa />
          </div>
          <div data-content-region="ssl_site_seal--global" />
        </div>
      </div>
    </footer>

  );
}

export default Footer;
